import React from 'react'
import './IconLoadStyle.scss'

const IconLoad = () => (
  <svg id="loader" className="hide" viewBox="0 0 50 50">
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
  </svg>
)

export default IconLoad
