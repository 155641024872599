const BASE_URL = {
  dev: 'https://bdn-dev.bettercalldave.io/ws/',
  uat: 'https://bdn-uat.bettercalldave.io/ws/',
  prod: 'https://bln.bdn.fr/ws/',
}

const GRAPHQL_URLS = {
  dev: 'https://bdn-dev.bettercalldave.io/graphql',
  uat: 'https://bdn-uat.bettercalldave.io/graphql',
  prod: 'https://bln.bdn.fr/graphql',
}

const BASE_CACHE_TIME = {
  dev: 30, // 30min
  uat: 60, // 1h
  prod: 720, // 12h
}

const idInternalTransfert = '5b916518-5c34-4c7f-8bbd-3d04eb00c3de'

const { REACT_APP_INFRA = 'dev', REACT_APP_PREFIX = '' } = process.env
const API_BASE_URL = BASE_URL[REACT_APP_INFRA]
const GRAPHQL_URL = GRAPHQL_URLS[REACT_APP_INFRA]
const CACHE_TIME = BASE_CACHE_TIME[REACT_APP_INFRA]

export default {
  API_BASE_URL,
  REACT_APP_PREFIX,
  GRAPHQL_URL,
  idInternalTransfert,
  CACHE_TIME,
}
