import PropTypes from 'prop-types'
import React from 'react'
import colors from '../../lib/colors'
import './LabelStyle.scss'

const Label = ({ danger, icon, children, success }) => {
  let color = ''
  if (danger) {
    color = colors.red
  } else if (success) {
    color = colors.green
  } else {
    color = colors.blue
  }
  return (
    <div className="label" style={{ color }}>
      {icon && <img src={icon} alt="" />}
      {children}
    </div>
  )
}

Label.propTypes = {
  icon: PropTypes.string,
  danger: PropTypes.bool,
  children: PropTypes.node.isRequired,
  success: PropTypes.bool,
}

Label.defaultProps = {
  icon: '',
  danger: false,
  success: false,
}

export default Label
