import PropTypes from 'prop-types'
import React from 'react'
import './AutoCompleteListStyle.scss'

const AutoCompleteList = ({ list, select }) => (
  <div className="auto-complete">
    {list.map((item) => (
      <button
        key={item?.id}
        type="button"
        onClick={() => {
          if (item?.active) {
            select(item)
          }
        }}
      >
        <p
          className={!item?.active ? 'client--inactive' : ''}
        >{`${item?.internalId} - ${item?.displayName}`}</p>
      </button>
    ))}
  </div>
)

AutoCompleteList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  select: PropTypes.func.isRequired,
}

export default AutoCompleteList
