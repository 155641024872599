import PropTypes from 'prop-types'
import React from 'react'
import IconLoad from '../icons/IconLoad/IconLoad'
import './ButtonStyle.scss'

const btnClass = (icon, type, appearance) => {
  if (icon) {
    return 'icon-btn'
  }
  if (type === 'outline') {
    return `${appearance}-outline`
  }
  if (type === 'filled') {
    return `${appearance}-btn`
  }
}

const Button = ({ text, icon, onClick, type, appearance, disabled, width, loading }) => (
  <button
    disabled={disabled || loading}
    type="button"
    className={btnClass(icon, type, appearance)}
    style={{ width }}
    onClick={() => onClick()}
  >
    {icon && <img src={icon} alt="" />}
    <p className="btn-text">{text}</p>
    {loading && <IconLoad />}
  </button>
)

Button.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['outline', 'filled']),
  appearance: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  width: PropTypes.string,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  icon: null,
  type: 'filled',
  appearance: 'primary',
  disabled: false,
  width: 'fit-content',
  loading: false,
}

export default Button
