import { useLiveQuery } from 'dexie-react-hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { Button, Footer, Header, InputSimple, OrderTable } from '../../components'
import { db } from '../../lib/db'
import './EvacuationSummaryStyle.scss'

const EvacuationSummary = () => {
  const { t } = useTranslation('orderSummary')
  const history = useHistory()
  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())

  const [destinationSite, setDestinationSite] = useState('')
  const [customer, setCustomer] = useState('')
  const [forwarder, setForwarder] = useState('')
  const [plate, setPlate] = useState('')
  const [constructionNb, setConstructionNb] = useState('')
  const [error, setError] = useState(false)
  const [validating, setValidating] = useState(false)

  useEffect(() => {
    if (lastOrder) {
      setCustomer(lastOrder.customer)
      setForwarder(lastOrder.forwarder)
      setPlate(lastOrder.plate)
      setConstructionNb(lastOrder.constructionNb)
    }
  }, [lastOrder.customer])

  const validate = () => {
    const deliveredOutProduct = lastOrder?.outMaterials
      ?.filter((product) => product.delivered)
      ?.map((o) => o.delivered)
    const deliveredInProduct = lastOrder?.inMaterials
      ?.filter((product) => product.delivered)
      ?.map((o) => o.delivered)
    let sum1 = 0
    let sum2 = 0
    if (deliveredOutProduct.length) {
      sum1 = deliveredOutProduct.reduce((a, c) => a + c)
    }
    if (deliveredInProduct.length) {
      sum2 = deliveredInProduct.reduce((a, c) => a + c)
    }

    if (sum1 || sum2) {
      setValidating(true)
      db.offlineOrders.update(lastOrder.id, {
        customer,
        forwarder,
        plate,
        destinationSite,
        constructionNb,
      })
      history.push('/sign?process=evacuate')
    } else {
      setError(true)
    }
  }

  const deliverNum = (id, quantity, outProduct) => {
    if (outProduct) {
      lastOrder.outMaterials.forEach((m) => {
        const material = m
        if (material.id === id) {
          material.delivered = parseFloat(quantity)
        }
      })
      db.offlineOrders.update(lastOrder.id, {
        outMaterials: [...lastOrder.outMaterials],
      })
    } else {
      lastOrder.inMaterials.forEach((m) => {
        const material = m
        if (material.id === id) {
          material.delivered = parseFloat(quantity)
        }
      })
      db.offlineOrders.update(lastOrder.id, {
        inMaterials: [...lastOrder.inMaterials],
      })
    }
  }

  return (
    <div>
      <Header title={t('evacuateSummary')} />
      <div className="summary">
        <div className="order-product-table">
          <OrderTable
            inProducts={lastOrder?.inMaterials ? lastOrder?.inMaterials : []}
            outProducts={lastOrder?.outMaterials ? lastOrder?.outMaterials : []}
            updateValue={(a, b, c) => deliverNum(a, b, c)}
          />
          <div className="add-link">
            <Link to="/catalog?process=evacuate">+ {t('add')}</Link>
          </div>
          <p className="blue modify">{t('toModify2')}</p>
          {error && <p className="error">{t('error')}</p>}
        </div>
        <div className="infos-half">
          <div className="order-infos">
            <div className="infos-line">
              <p>{t('customer')}</p>
              <p className="customer">{customer}</p>
            </div>
            <div className="infos-line">
              <p>{t('forwarder')}</p>
              <InputSimple type="text" value={forwarder} onChange={(e) => setForwarder(e)} />
            </div>
            <div className="infos-line">
              <p>{t('plate')}</p>
              <InputSimple
                type="text"
                value={plate}
                onChange={(e) => setPlate(e)}
                autoComplete={true}
              />
            </div>
            <div className="infos-line">
              <p>{t('sitePlaceholder')} :</p>
              <InputSimple
                type="text"
                value={destinationSite}
                onChange={(e) => setDestinationSite(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer>
        <Button
          text={t('back')}
          type="outline"
          appearance="secondary"
          width="32%"
          onClick={() => history.push('/catalog?process=evacuate')}
        />
        <Button
          text={t('quit')}
          type="outline"
          appearance="secondary"
          width="32%"
          onClick={() => history.push('/endPending')}
        />
        <Button text={t('sign')} width="32%" loading={validating} onClick={() => validate()} />
      </Footer>
    </div>
  )
}

export default EvacuationSummary
