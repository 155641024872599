/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { useLiveQuery } from 'dexie-react-hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Header, OrderItem, TableTop } from '../../components'
import { db } from '../../lib/db'
import '../../spinner.css'
import './OrdersStyle.scss'

const Orders = () => {
  const { t } = useTranslation('orders')
  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())

  const orders = useLiveQuery(async () => {
    if (lastOrder?.customerId) {
      const sales = await db.sales.where('clientId').equals(lastOrder.customerId).toArray()
      return sales
    }
    return []
  }, [lastOrder])

  const history = useHistory()

  const selectOrder = (order) => {
    const MAT = order.materials.filter((e) => e.family === 'MATERIAL')
    const RUB = order.materials.filter((e) => e.family === 'RUBBLE')
    db.offlineOrders.update(lastOrder.id, {
      orderId: order.id,
      customer: lastOrder.customer,
      plate: lastOrder.plate,
      customerId: lastOrder.customerId,
      code: order.code,
      site: order.siteByIdSite.name,
      siteId: order.siteByIdSite.id,
      forwarder: lastOrder.forwarder,
      externalReference: order.externalReference,
      outMaterials: MAT,
      inMaterials: RUB,
    })
    history.push('/summary')
  }

  const totalSum = (products) => {
    if (products.length > 0) {
      const sum = products.map((o) => o.quantity).reduce((a, c) => a + c)
      return parseFloat(sum).toFixed(2)
    }
  }

  const goBack = () => {
    db.offlineOrders.delete(lastOrder.id)
    history.push('/endPending')
  }

  return (
    <>
      {orders ? (
        <div>
          <Header title={t('orders')} />
          <TableTop>
            <p>{t('customer')}</p>
            <p>{t('orderNumber')}</p>
            {false && <p>{t('city')}</p>}
            <p>{t('products')}</p>
            <p>
              {t('total')}
              {false && <span className="blue">({t('left')})</span>}
            </p>
            <p>{t('status')}</p>
          </TableTop>
          <div className="btns-group">
            <div>
              <Button
                text={t('newOrder')}
                width="300px"
                onClick={() => history.push('/orders/new')}
              />
              <br />
              <Button
                text={t('back')}
                type="outline"
                appearance="secondary"
                width="300px"
                onClick={goBack}
              />
            </div>
          </div>
          <div className="orders">
            {orders && orders.length > 0 ? (
              <div className="orders-item-content">
                {orders.map((order) => (
                  <OrderItem
                    key={order.id}
                    customer={lastOrder.customer}
                    newOrder={false}
                    orderNumber={order.code}
                    city=""
                    products={order.materials}
                    total={totalSum(order.materials)}
                    left={null}
                    onClick={() => selectOrder(order)}
                  />
                ))}
              </div>
            ) : (
              <div>
                <div className="no-orders d-flex">
                  <img src="/images/magnifyingglass.svg" alt="" />
                  <p>{t('noOrders')}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="global">
          <div className="ring" style={{ fontWeight: 'bolder' }} />
          {t('loading')}
        </div>
      )}
    </>
  )
}

export default Orders
