import { Offline } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from '../config'

const attachmentUrlFromDsn = (dsn, eventId) => {
  const { host, path, projectId, port, protocol, user } = dsn
  return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
    path !== '' ? `/${path}` : ''
  }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`
}

const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

export const initSentry = () => {
  let environment = 'development'
  if (config.REACT_APP_INFRA === 'uat') {
    environment = 'staging'
  } else if (config.REACT_APP_INFRA === 'prod') {
    environment = 'production'
  }

  if (!isLocal) {
    Sentry.init({
      dsn: 'https://a1bf0f23c4104d01a1ee1850a38cbdbd@o530648.ingest.sentry.io/6193567',
      integrations: [new Integrations.BrowserTracing(), new Offline()],

      enableAutoSessionTracking: true,
      environment,

      // Sessions close after app is 10 seconds in the background.
      sessionTrackingIntervalMillis: 10000,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })
    Sentry.setTag('plateforme', 'backoffice')
  }
}

export const captureMessageWithAttachment = (data, error) => {
  if (!isLocal) {
    const formData = new FormData()
    try {
      console.log('sending sentry', data, error)
      Sentry.withScope((scope) => {
        scope.setTransactionName(`pwa - ${data.request}`)
        const msgId = Sentry.captureMessage(`[GraphQL error]: ${error?.message}`)
        const client = Sentry.getCurrentHub().getClient()
        const endpoint = attachmentUrlFromDsn(client.getDsn(), msgId)
        formData.append(
          'my-attachment',
          // eslint-disable-next-line no-undef
          new Blob(
            [
              JSON.stringify({
                ...data,
                message: error?.message,
                url: window.location.href,
                accessToken: localStorage.getItem('access_token'),
                refreshToken: localStorage.getItem('refresh_token'),
              }),
            ],
            {
              type: 'application/json',
            }
          ),
          'logs.json'
        )
        if (navigator.onLine) {
          fetch(endpoint, {
            method: 'POST',
            body: formData,
          }).catch((ex) => {
            // we have to catch this otherwise it throws an infinite loop in Sentry
            console.error(ex)
          })
        } else {
          const localStorageSentryRequests = localStorage.getItem('sentry')
          if (localStorageSentryRequests) {
            const temp = JSON.parse(localStorageSentryRequests)
            setTimeout(() => {
              temp.push({ data, error })
              const tempSend = JSON.stringify(temp)
              setTimeout(() => {
                localStorage.setItem('sentry', tempSend)
              }, 1000)
            }, 1000)
          } else {
            const temp = JSON.stringify([{ data, error }])
            setTimeout(() => {
              localStorage.setItem('sentry', temp)
            }, 1000)
          }
        }
      })
    } catch (ex) {
      console.error(ex)
    }
  }
}
