import PropTypes from 'prop-types'
import React from 'react'
import './ReviewItemStyle.scss'

const ReviewItem = ({ product, toSend }) => (
  <div className="review-item">
    <div>
      <p>{product.description || product.materialByIdMaterial.description}</p>
    </div>
    <div className={`${!toSend ? 'waste' : ''} quantity`}>
      <p>{`${product.delivered} T`}</p>
    </div>
  </div>
)

ReviewItem.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    delivered: PropTypes.number,
    materialByIdMaterial: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
  toSend: PropTypes.bool,
}

ReviewItem.defaultProps = {
  toSend: false,
}

export default ReviewItem
