import { useLiveQuery } from 'dexie-react-hooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Header, Input } from '../../components'
import useNetwork from '../../hooks/useNetwork'
import { db } from '../../lib/db'
import './HomeStyle.scss'

const Home = () => {
  const { t } = useTranslation('home')
  const [customer, setCustomer] = useState('')
  const [customerId, setCustomerId] = useState()
  const [forwarder, setForwarder] = useState('')
  const [plate, setPlate] = useState('')
  const [validateError, setValidateError] = useState(false)
  const [evacuateError, setEvacuateError] = useState(false)
  const [validating1, setValidating1] = useState(false)
  const [validating2, setValidating2] = useState(false)
  const [resyncing, setResyncing] = useState(false)
  const network = useNetwork()
  const history = useHistory()

  const syncTime = useLiveQuery(async () => {
    const dt = await db.lastSync.toArray()
    return dt[0]
  })

  const offlineOrders = useLiveQuery(async () => {
    const dt = await db.offlineOrders.where('completed').equalsIgnoreCase('completed').count()
    return dt
  })

  const validate = () => {
    if (!customer || !plate) {
      setValidateError(true)
      setEvacuateError(false)
    } else {
      setValidating1(true)
      db.offlineOrders.add({
        customer,
        customerId,
        forwarder,
        plate,
        process: 'validate',
      })
      history.push('/orders')
    }
  }

  const evacuate = () => {
    if (!plate && !forwarder) {
      setValidateError(false)
      setEvacuateError(true)
    } else {
      setValidating2(true)
      db.offlineOrders.add({
        customer: 'Briqueterie du Nord',
        forwarder,
        plate,
        process: 'evacuate',
      })
      history.push('/catalog?process=evacuate')
    }
  }

  const resync = async () => {
    setResyncing(true)
    Promise.all([
      db.clients.clear(),
      db.sales.clear(),
      db.rubble.clear(),
      db.material.clear(),
    ]).then(
      () => {
        setResyncing(false)
        history.push('/')
      },
      () => {
        setResyncing(false)
        history.push('/')
      }
    )
  }

  return (
    <div>
      <Header />
      <div className="home">
        <div className="mb-4">
          <Input
            width="100%"
            value={customer}
            type="text"
            error={validateError}
            placeholder={t('customerName')}
            onChange={(value) => {
              if (value.displayName && value.id) {
                setCustomer(value?.displayName)
                setCustomerId(value?.id)
              } else {
                setCustomer(value.toUpperCase())
                setCustomerId(null)
              }
            }}
            autoComplete={true}
          />
        </div>
        <div className="mb-4">
          <Input
            width="100%"
            value={forwarder}
            type="text"
            error={evacuateError}
            placeholder={t('forwarderName')}
            onChange={(value) => setForwarder(value.toUpperCase())}
          />
        </div>
        <div>
          <Input
            width="100%"
            value={plate}
            type="text"
            error={validateError || evacuateError}
            placeholder={t('plate')}
            onChange={(value) => setPlate(value.trim().toUpperCase())}
          />
        </div>
        {(validateError || evacuateError) && <p className="error mt-1">{t('mandatory')}</p>}
        <div className="d-flex justify-content-between mt-4">
          <Button
            width="49%"
            text={t('evacuate')}
            loading={validating2}
            appearance="secondary"
            type="filled"
            onClick={() => evacuate()}
          />
          <Button
            width="49%"
            text={t('validate')}
            loading={validating1}
            onClick={() => validate()}
          />
        </div>
      </div>
      <div className="footer">
        <div className="footer_sync">
          {offlineOrders > 0 && (
            <span style={{ marginRight: '5px' }}>{t('toSync', { num: offlineOrders })}</span>
          )}
          {syncTime && (
            <p className="footer_sync_text">
              {' '}
              {t('lastSync')} {syncTime.date}
            </p>
          )}
          <Button
            disabled={!network}
            text={resyncing ? 'Préparation de la synchro' : 'Synchroniser'}
            type="outline"
            onClick={resync}
          />
        </div>
      </div>
    </div>
  )
}

export default Home
