import PropTypes from 'prop-types'
import React from 'react'
import { ProductItem } from '../index'
import './ProductListStyle.scss'

const ProductList = ({
  products,
  favorites,
  checkedProducts,
  onCheck,
  addToFavorite,
  deleteFavorite,
}) => (
  <div className="product-list">
    <div className="row">
      {products
        .filter((e) => favorites?.find((f) => f.id === e.id))
        ?.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            favorited={true}
            checked={
              checkedProducts.findIndex(
                (e) => e?.materialByIdMaterial?.id === product.id || e.matId === product.id
              ) !== -1
            }
            onCheck={() => onCheck(product)}
            onClickFav={() => deleteFavorite(product.id)}
          />
        ))}
      {products
        .filter((e) => !favorites?.find((f) => f.id === e.id))
        ?.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            favorited={false}
            checked={
              checkedProducts.findIndex(
                (e) => e?.materialByIdMaterial?.id === product.id || e.matId === product.id
              ) !== -1
            }
            onCheck={() => onCheck(product)}
            onClickFav={() => addToFavorite(product.id)}
          />
        ))}
    </div>
  </div>
)

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  favorites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      materialByIdMaterial: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  onCheck: PropTypes.func.isRequired,
  addToFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
}

export default ProductList
