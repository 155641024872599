import PropTypes from 'prop-types'
import React from 'react'
import colors from '../../lib/colors'
import './OrderItemLayoutStyle.scss'

const OrderItemLayout = ({ status, customer, children }) => {
  let statusColor
  if (status === 'ok') {
    statusColor = colors.blue
  }
  if (status === 'error') {
    statusColor = colors.red
  }
  if (status === 'synced') {
    statusColor = colors.green
  }
  return (
    <div className="order-item-status" style={{ background: statusColor }}>
      <div className="order-item">
        <p className="customer">{customer}</p>
        {children}
      </div>
    </div>
  )
}

OrderItemLayout.propTypes = {
  status: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default OrderItemLayout
