import PropTypes from 'prop-types'
import React from 'react'
import IconStar from '../icons/IconStar/IconStar'
import './ProductItemStyle.scss'

const ProductItem = ({ product, checked, onCheck, onClickFav, favorited }) => (
  <div className="product-item">
    <button type="button" className="no-style-btn" onClick={() => onCheck(product)}>
      <div className={checked ? 'product-display product-display-checked' : 'product-display'}>
        {checked && (
          <div className="checked">
            <img src="images/yt-check.svg" alt="" />
          </div>
        )}
        <p>{product?.description}</p>
      </div>
    </button>
    {!checked && (
      <button className="fav-btn" type="button" onClick={onClickFav}>
        <IconStar favorited={favorited} />
      </button>
    )}
  </div>
)

ProductItem.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  onClickFav: PropTypes.func.isRequired,
  favorited: PropTypes.bool.isRequired,
}

export default ProductItem
