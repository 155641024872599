import React, { useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { FooterSite, PrivateRoute } from './components'
import history from './lib/history'

import {
  EndReview,
  EvacuationSummary,
  History,
  Home,
  Loading,
  NewOrder,
  Orders,
  OrderSummary,
  ProductPage,
  Signature,
  WaitingPage,
} from './pages'
import './spinner.css'

const RouterPages = () => {
  const noBack = () => {
    history.go(1)
  }
  useEffect(() => {
    window.addEventListener('popstate', noBack)
    return () => {
      window.removeEventListener('popstate', noBack)
    }
  }, [])

  const CallBack = () => {
    useEffect(() => {
      history.goBack()
    })

    return null
  }

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/pending" component={WaitingPage} />
          <PrivateRoute exact path="/" component={Loading} />
          <PrivateRoute exact path="/endPending" component={Home} />
          <PrivateRoute exact path="/orders" component={Orders} />
          <PrivateRoute exact path="/orders/new" component={NewOrder} />
          <PrivateRoute exact path="/catalog" component={ProductPage} />
          <PrivateRoute exact path="/summary" component={OrderSummary} />
          <PrivateRoute exact path="/evacuation" component={EvacuationSummary} />
          <PrivateRoute exact path="/sign" component={Signature} />
          <PrivateRoute exact path="/final" component={EndReview} />
          <PrivateRoute exact path="/history" component={History} />
          <Route path="*" component={CallBack} />
        </Switch>
        <FooterSite />
      </Router>
    </>
  )
}

export default RouterPages
