import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CellInput } from '../index'
import './OrderTableItemStyle.scss'

const OrderTableItem = ({ product, quantity, toSend, updateValue }) => {
  const [left, setLeft] = useState(product.delivered ? quantity - product.delivered : quantity)
  const [value, setValue] = useState(product.delivered || '')
  const { pathname } = window.location
  return (
    <tr className="product-row">
      <td className="product-name">
        <img src={toSend ? '/images/sended.svg' : '/images/received.svg'} alt="" />
        <Link
          to={`/catalog?tab=${toSend ? 'out' : 'in'}&process=${
            pathname.includes('evacuation') ? 'evacuate' : ''
          }`}
        >
          <p>{product.description || product.materialByIdMaterial.description}</p>
        </Link>
      </td>
      <td className="left-to-deliver">
        <p>{quantity ? `${left} T` : 'N/A'}</p>
      </td>
      <td className="input-cell">
        <CellInput
          value={`${value}`}
          onChange={(e) => {
            setValue(e)
            if (left !== 'N/A') {
              setLeft((quantity - e).toFixed(2))
            }
            updateValue(product.id || product.matId, e, toSend)
          }}
        />
        <p className="unit">T</p>
      </td>
    </tr>
  )
}

OrderTableItem.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    matId: PropTypes.string,
    delivered: PropTypes.number,
    materialByIdMaterial: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
  quantity: PropTypes.number,
  toSend: PropTypes.bool,
  updateValue: PropTypes.func.isRequired,
}

OrderTableItem.defaultProps = {
  quantity: null,
  toSend: false,
}

export default OrderTableItem
