import PropTypes from 'prop-types'
import React from 'react'

const IconStar = ({ favorited }) => (
  <svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.9311 41.4101L35.4151 41.0992L34.8991 41.4101L29.3658 44.7434L29.3553 44.7498L29.3449 44.7563C29.2687 44.8049 29.2287 44.806 29.1982 44.8035C29.1134 44.7964 29.0672 44.7736 29.0291 44.744C28.9542 44.6857 28.8964 44.6152 28.851 44.5201L28.8509 44.5201C28.8476 44.5138 28.8352 44.4831 28.856 44.3923C28.8561 44.392 28.8561 44.3918 28.8562 44.3915L30.3224 38.0934L30.4594 37.505L30.0022 37.11L25.1115 32.8846C25.0279 32.8078 25.0147 32.7604 25.0101 32.7389L25.0101 32.7389L25.0092 32.7349C24.9937 32.6641 24.9978 32.6073 25.0216 32.5417C25.0586 32.4399 25.1015 32.3867 25.1397 32.3531C25.1447 32.3494 25.1933 32.3161 25.3334 32.2935L31.7691 31.7295L32.3694 31.6769L32.6033 31.1216L35.1033 25.1883L35.1033 25.1883L35.1049 25.1846C35.1376 25.1062 35.1675 25.0841 35.1946 25.0687L35.196 25.0678C35.2928 25.0124 35.3617 25 35.4151 25C35.4685 25 35.5382 25.0124 35.6362 25.0682C35.6624 25.0833 35.6925 25.1056 35.7254 25.1846L35.7254 25.1846L35.7269 25.1883L38.2269 31.1216L38.4609 31.6769L39.0612 31.7295L45.4968 32.2935C45.637 32.3161 45.6855 32.3494 45.6906 32.3531C45.7288 32.3867 45.7716 32.4399 45.8087 32.5417C45.8328 32.608 45.8371 32.6657 45.822 32.7367C45.8168 32.7601 45.8017 32.8084 45.7188 32.8846L40.828 37.11L40.3708 37.505L40.5078 38.0934L41.9741 44.3915C41.9741 44.3918 41.9742 44.3921 41.9743 44.3924C41.9951 44.4831 41.9826 44.5138 41.9793 44.5201L41.9793 44.5201C41.9339 44.6152 41.8761 44.6857 41.8012 44.744C41.7631 44.7736 41.7168 44.7964 41.6321 44.8035C41.6016 44.806 41.5616 44.8049 41.4853 44.7563L41.475 44.7498L41.4645 44.7434L35.9311 41.4101Z"
      fill={favorited ? 'white' : 'transparent'}
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)

IconStar.propTypes = {
  favorited: PropTypes.bool.isRequired,
}

export default IconStar
