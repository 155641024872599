import axios from 'axios'
import config from '../config'
import { captureMessageWithAttachment } from '../lib/sentry'

const api = axios.create({
  baseURL: config.API_BASE_URL,
})

const post = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'post',
    url: path,
    data,
    headers,
  })
  return res.data
}

const get = async (path, params = {}, headers = {}) => {
  const res = await api({
    method: 'get',
    url: path,
    params,
    headers,
  })
  return res.data
}

const put = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'put',
    url: path,
    data,
    headers,
  })
  return res.data
}

const del = async (path, data = {}) => {
  const res = await api({
    method: 'delete',
    url: path,
    data,
  })
  return res.data
}

const uploadFile = async (idDelivery, files, token, label) => {
  const formData = new FormData()
  formData.append(label, files)
  const url = `${config.API_BASE_URL}outbound_deliveries/${idDelivery}/signature`
  const response = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

const sendSeed = async (seed) => {
  const res = await post('devices/seed', {
    seed,
  })
  return res
}

const checkIfVerified = async (id, seed) => {
  const res = await get(`seeds/${id}/token?seed=${seed}`)
  return res
}

const refreshAccessToken = async (token) => {
  try {
    return await get(
      'token',
      {},
      {
        Authorization: `bearer ${token}`,
        Accept: 'application/json',
      }
    )
  } catch (error) {
    if (navigator.onLine) {
      const event = {
        request: 'refresh token',
        variables: {
          token,
        },
      }
      captureMessageWithAttachment(event, error)
    }
    console.log(error)
  }
}

export default {
  get,
  post,
  put,
  del,
  uploadFile,
  sendSeed,
  checkIfVerified,
  refreshAccessToken,
}
