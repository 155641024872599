import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '..'
import './ModalStyle.scss'

const Modal = ({ title, description, cancelTxt, onCancel, okTxt, onOk }) => (
  <div className="bdn-modal">
    <p className="modal-title">{title}</p>
    <p>{description}</p>
    <div>
      <Button
        appearance="secondary"
        type="outline"
        text={cancelTxt}
        onClick={() => onCancel()}
        width="30%"
      />
      <Button text={okTxt} onClick={() => onOk()} width="60%" />
    </div>
  </div>
)

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cancelTxt: PropTypes.string.isRequired,
  okTxt: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
}

export default Modal
