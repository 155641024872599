import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { InputModal } from '../index'
import './InputStyle.scss'

const Input = ({ value, placeholder, type, required, onChange, width, autoComplete, error }) => {
  const [modal, setModal] = useState(false)
  const [inputError, setInputError] = useState(false)

  return (
    <>
      <div role="button" onClick={() => setModal(true)} style={{ width }} className="styled-input">
        <input
          className={`${value ? 'success' : ''} ${inputError || (error && !value) ? 'error' : ''}`}
          type={type}
          value={value}
          placeholder={placeholder}
        />
        {value && !inputError && <img src="images/check.svg" alt="" />}
      </div>
      {modal && (
        <InputModal
          value={value}
          placeholder={placeholder}
          type={type}
          setValue={(content) => {
            onChange(content)
            if (!content && required) {
              setInputError(true)
            } else {
              setInputError(false)
            }
          }}
          closeModal={() => setModal(false)}
          autoCompleteList={autoComplete}
        />
      )}
    </>
  )
}

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  autoComplete: PropTypes.bool,
  error: PropTypes.bool,
}

Input.defaultProps = {
  value: '',
  required: false,
  width: 'fit-content',
  autoComplete: false,
  error: false,
  placeholder: '',
}

export default Input
