import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, OrderItemLayout, ProductDropDown } from '../index'
import './OrderItemStyle.scss'

const OrderItem = ({ newOrder, customer, products, orderNumber, total, left, city, onClick }) => {
  const { t } = useTranslation('orderSummary')

  return (
    <OrderItemLayout status={newOrder === true ? 'ok' : 'error'} customer={customer}>
      <p>{orderNumber || 'N° Commande'}</p>
      {false && <p>{city}</p>}
      <ProductDropDown products={products} />
      <p className="weight-600">
        {total}T{left && <span className="blue">{` (${left}T)`}</span>}
      </p>
      <button type="button" className="no-style-btn" onClick={() => onClick()}>
        {newOrder === true ? (
          <Label>
            <p className="weight-700">{t('delivery')}</p>
          </Label>
        ) : (
          <Label danger>
            <p className="weight-700">{t('continue')}</p>
          </Label>
        )}
      </button>
    </OrderItemLayout>
  )
}

OrderItem.propTypes = {
  newOrder: PropTypes.bool.isRequired,
  customer: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderNumber: PropTypes.string,
  total: PropTypes.string.isRequired,
  left: PropTypes.number,
  city: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

OrderItem.defaultProps = {
  orderNumber: '',
  left: null,
}

export default OrderItem
