import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useClickAway } from 'use-click-away'
import './ProductDropDownStyle.scss'

const ProductDropDown = ({ products }) => {
  const [open, setOpen] = useState(false)
  const ref = React.useRef(null)
  useClickAway(ref, () => {
    setOpen(false)
  })

  return (
    <div className="products" ref={ref}>
      <button onClick={() => setOpen(!open)} className="no-style-btn" type="button">
        <p className="product">Produit.s</p>
      </button>
      {open && (
        <div className="product-dropdown">
          {products.map((product) => (
            <p
              key={product.id || product.matId || product.materialByIdMaterial.id}
              className={
                product?.family === 'RUBBLE' || product?.materialByIdMaterial?.family === 'RUBBLE'
                  ? 'blue'
                  : ''
              }
            >
              {product.description || product.materialByIdMaterial.description}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

ProductDropDown.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
}

export default ProductDropDown
