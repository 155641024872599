import { setContext } from '@apollo/client/link/context'
import ApolloClientIDB from '@wora/apollo-offline/lib/ApolloClientIDB'
import { HttpLink } from 'apollo-link-http'
import React from 'react'
import { Redirect } from 'react-router-dom'
import api from './api'
import config from './config'
import { isUserTokenExpired } from './lib/helpers'

const httpLink = new HttpLink({
  uri: config.GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')
  if (!accessToken) {
    return {
      headers,
    }
  }
  if (accessToken && isUserTokenExpired(accessToken)) {
    return api
      .refreshAccessToken(refreshToken)
      .then((response) => {
        localStorage.setItem('access_token', response.access_token)
        localStorage.setItem('refresh_token', response.refresh_token)
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${response.access_token}`,
          },
        }
      })
      .catch(() => {
        localStorage.clear()
        return <Redirect to="/pending" />
      })
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  }
})

const cacheOptions = {
  dataIdFromObject: (o) => o.id,
}

const client = ApolloClientIDB.create(
  { link: authLink.concat(httpLink) },
  {
    cacheOptions,
  }
)

export default client
