import gql from 'graphql-tag'

export const ORDER_INFOS = gql`
  query orderInfos {
    infos @client
  }
`

export const MATERIALS_ORDER = gql`
  query orderMaterials {
    outMaterials @client
    inMaterials @client
  }
`

export const GET_SIGNATURE = gql`
  query getSignature {
    signature @client
  }
`

export const GET_CLIENTS = gql`
  query getClients($greaterThan: Datetime!, $idSite: UUID!) {
    clients(filter: { internalId: { startsWith: "M" } }) {
      edges {
        node {
          active
          displayName
          id
          internalId
          salesOrdersByIdClient(
            filter: {
              createdAt: { greaterThan: $greaterThan }
              finished: { equalTo: false }
              idSite: { equalTo: $idSite }
            }
          ) {
            nodes {
              id
              code
              externalReference
              salesOrderItemsByIdSalesOrder {
                nodes {
                  quantity
                  materialByIdMaterial {
                    id
                    description
                    idProductCategory
                    productCategoryByIdProductCategory {
                      family
                    }
                  }
                }
              }
              siteByIdSite {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SITES = gql`
  query getSites {
    sites {
      nodes {
        name
        id
      }
    }
  }
`

export const GET_FORWARDERS = gql`
  query getForwarders {
    freightForwarders {
      nodes {
        displayName
        id
      }
    }
  }
`

export const CREATE_DELIVERIES = gql`
  mutation createDeliveries(
    $id: UUID!
    $idSite: String
    $idSaleOrder: String
    $idConstructionSite: String
    $idTruck: String
    $code: String
    $forwardingAgent: String
    $idFreightForwarder: String
    $constructionSiteAddress: String
    $constructionSiteCity: String
    $constructionSiteCode: String
    $constructionSitePostcode: String
    $truckLicencePlate: String
    $saleOrderClientName: String
    $idClient: String
    $value: Datetime!
    $idInternalTransfert: String
    $externalReference: String
    $destinationSite: String
  ) {
    createDraftOutboundDelivery(
      input: {
        draftOutboundDelivery: {
          idSite: $idSite
          shippingPeriod: {
            start: { value: $value, inclusive: true }
            end: { value: $value, inclusive: true }
          }
          id: $id
          idSaleOrder: $idSaleOrder
          idConstructionSite: $idConstructionSite
          idTruck: $idTruck
          code: $code
          constructionSiteAddress: $constructionSiteAddress
          constructionSiteCity: $constructionSiteCity
          constructionSiteCode: $constructionSiteCode
          constructionSitePostcode: $constructionSitePostcode
          truckLicencePlate: $truckLicencePlate
          saleOrderClientName: $saleOrderClientName
          idClient: $idClient
          forwardingAgent: $forwardingAgent
          idFreightForwarder: $idFreightForwarder
          idInternalTransfert: $idInternalTransfert
          externalReference: $externalReference
          destinationSite: $destinationSite
        }
      }
    ) {
      clientMutationId
      draftOutboundDelivery {
        id
      }
    }
  }
`

export const CREATE_DELIVERIY_ITEMS = gql`
  mutation createDeliveryItem(
    $id: UUID!
    $idDraftOutboundDelivery: UUID!
    $idMaterial: UUID!
    $quantity: Float!
  ) {
    createDraftOutboundDeliveryItem(
      input: {
        draftOutboundDeliveryItem: {
          id: $id
          idDraftOutboundDelivery: $idDraftOutboundDelivery
          idMaterial: $idMaterial
          quantity: $quantity
        }
      }
    ) {
      clientMutationId
      draftOutboundDeliveryItem {
        id
        idMaterial
      }
    }
  }
`

export const GET_DELIVERIES = gql`
  query getDeliveries($site: String!, $startDate: Datetime!, $endDate: Datetime!) {
    draftOutboundDeliveries(
      filter: {
        shippingPeriod: {
          overlaps: {
            start: { value: $startDate, inclusive: true }
            end: { value: $endDate, inclusive: true }
          }
        }
        and: { idSite: { equalTo: $site } }
      }
    ) {
      nodes {
        id
        code
        forwardingAgent
        truckLicencePlate
        constructionSiteAddress
        constructionSiteCity
        constructionSiteCode
        idConstructionSite
        idTruck
        createdAt
        saleOrderClientName
        idClient
        idInternalTransfert
        externalReference
        status
        destinationSite
        draftOutboundDeliveryItemsByIdDraftOutboundDelivery {
          nodes {
            quantity
            materialByIdMaterial {
              id
              description
              idProductCategory
              productCategoryByIdProductCategory {
                family
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CATEGORIES = gql`
  query getCategories($family: ProductCategoryFamily, $idSite: UUID) {
    productCategories(condition: { family: $family }) {
      nodes {
        id
        materialsByIdProductCategory {
          nodes {
            siteMaterialsByIdMaterial(condition: { idSite: $idSite }) {
              nodes {
                materialByIdMaterial {
                  id
                  description
                  productCategoryByIdProductCategory {
                    family
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_USER = gql`
  query getUser($id: UUID!) {
    user(id: $id) {
      id
      idSite
      lastname
      firstname
      siteByIdSite {
        name
      }
    }
  }
`

export const UPDATE_STATUS = gql`
  mutation updateStatus($id: UUID!, $status: OutboundDeliveryStatus) {
    updateDraftOutboundDelivery(input: { patch: { status: $status }, id: $id }) {
      clientMutationId
      draftOutboundDelivery {
        id
        status
      }
    }
  }
`

export const GET_DEVICE_VALIDATION = gql`
  query getDeviceValidation($idDeviceSeed: UUID! = "af858c4f-ccf3-415c-b6a8-3376642f2aeb") {
    deviceByIdDeviceSeed(idDeviceSeed: $idDeviceSeed) {
      id
      isValid
    }
  }
`

export const GET_SITE_BY_ID = gql`
  query getSiteById($id: UUID!) {
    site(id: $id) {
      code
      name
    }
  }
`

export const CONSTRUCTION_SITE_BY_CODE = gql`
  query constructionSiteByCode($code: String!) {
    constructionSiteByCode(code: $code) {
      city
      code
      id
      address
    }
  }
`

export const TRUCK_BY_LICENSEPLATE = gql`
  query trucks($plicensePlate: String!) {
    trucks(filter: { licencePlate: { like: $plicensePlate } }) {
      nodes {
        id
        licencePlate
        unloadedWeight
      }
    }
  }
`

export const GET_CONSTRUCTION_SITE_AND_TRUCK = gql`
  query getConstructionSiteByCodeAndTrucks($code: String!, $plicensePlate: String!) {
    constructionSiteByCode(code: $code) {
      city
      code
      id
      address
    }
    trucks(filter: { licencePlate: { like: $plicensePlate } }) {
      nodes {
        id
        licencePlate
        unloadedWeight
      }
    }
  }
`

export const UPDATE_DELIVERY = gql`
  mutation updateDraftOutboundDelivery(
    $id: UUID!
    $idConstructionSite: String
    $idTruck: String
    $constructionSiteAddress: String
    $constructionSiteCity: String
  ) {
    updateDraftOutboundDelivery(
      input: {
        patch: {
          idConstructionSite: $idConstructionSite
          idTruck: $idTruck
          constructionSiteAddress: $constructionSiteAddress
          constructionSiteCity: $constructionSiteCity
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

export const GET_FAV_MATERIALS = gql`
  query favMaterials($id: UUID!) {
    favMaterials(filter: { idUser: { equalTo: $id } }) {
      nodes {
        materialByIdMaterial {
          id
        }
      }
    }
  }
`

export const CREATE_FAV_MATERIAL = gql`
  mutation createFavMaterial($idMaterial: UUID!, $idUser: UUID!) {
    createFavMaterial(input: { favMaterial: { idMaterial: $idMaterial, idUser: $idUser } }) {
      clientMutationId
    }
  }
`

export const DELETE_FAV_MATERIAL = gql`
  mutation MyMutation($idMaterial: UUID!, $idUser: UUID!) {
    deleteFavMaterialByIdMaterialAndIdUser(input: { idMaterial: $idMaterial, idUser: $idUser }) {
      clientMutationId
      deletedFavMaterialNodeId
    }
  }
`
