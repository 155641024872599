import { useLiveQuery } from 'dexie-react-hooks'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Footer, Header, HistoryItem } from '../../components'
import { GET_DELIVERIES, GET_USER, UPDATE_STATUS } from '../../gql'
import useNetwork from '../../hooks/useNetwork'
import { db } from '../../lib/db'
import './HistoryStyle.scss'

const History = () => {
  const { t } = useTranslation('history')
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [period, setPeriod] = useState('0')
  const [deliveries, setDeliveries] = useState([])
  const [loading, setLoading] = useState(true)

  const syncTime = useLiveQuery(async () => {
    const dt = await db.lastSync.toArray()
    return dt[0]
  })

  const offlineDeliveries = useLiveQuery(async () => {
    const offDeliveries = await db.deliveries.toArray()
    return offDeliveries
  }, [])

  const network = useNetwork()
  const { data: userData } = useQuery(GET_USER, {
    variables: {
      id: localStorage.getItem('access_token')
        ? jwtDecode(localStorage.getItem('access_token')).id
        : '',
    },
  })

  const { data } = useQuery(GET_DELIVERIES, {
    fetchPolicy: network ? 'cache-and-network' : 'cache-only',
    onCompleted: () => {
      setLoading(false)
    },
    variables: {
      site: userData?.user?.idSite,
      startDate: network
        ? moment().subtract(1, 'week').format('yyyy-MM-DD')
        : moment().subtract(1, 'day').format('yyyy-MM-DD'),
      endDate: moment().add(1, 'day').format('yyyy-MM-DD'),
    },
  })

  const [updateStatus] = useMutation(UPDATE_STATUS)

  useEffect(() => {
    if ((network && data) || (!network && offlineDeliveries)) {
      const sortedData = network
        ? data?.draftOutboundDeliveries.nodes.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        : offlineDeliveries?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      if (parseInt(period, 10) === 0) {
        setDeliveries(
          sortedData.filter(
            (e) =>
              moment(new Date(e.createdAt)).format('MMM Do YY') === moment().format('MMM Do YY') &&
              (e.saleOrderClientName?.toLowerCase().includes(search.toLowerCase()) ||
                e.truckLicencePlate?.toLowerCase().includes(search.toLowerCase()))
          )
        )
      } else if (parseInt(period, 10) === 1) {
        setDeliveries(
          sortedData.filter(
            (e) =>
              moment(new Date(e.createdAt)).format('MMM Do YY') ===
                moment().subtract(1, 'days').format('MMM Do YY') &&
              (e.saleOrderClientName?.toLowerCase().includes(search.toLowerCase()) ||
                e.truckLicencePlate?.toLowerCase().includes(search.toLowerCase()))
          )
        )
      } else if (parseInt(period, 10) === 2) {
        setDeliveries(
          sortedData.filter(
            (e) =>
              new Date(e.createdAt).getTime() > new Date(moment().subtract(1, 'weeks')).getTime() &&
              (e.saleOrderClientName?.toLowerCase().includes(search.toLowerCase()) ||
                e.truckLicencePlate?.toLowerCase().includes(search.toLowerCase()))
          )
        )
      } else if (parseInt(period, 10) === 3) {
        setDeliveries(
          sortedData.filter(
            (e) =>
              new Date(e.createdAt).getTime() >
                new Date(moment().subtract(1, 'months')).getTime() &&
              (e.saleOrderClientName?.toLowerCase().includes(search.toLowerCase()) ||
                e.truckLicencePlate?.toLowerCase().includes(search.toLowerCase()))
          )
        )
      }
    }
  }, [data, period, search, offlineDeliveries])

  const report = (id, status) => {
    updateStatus({
      variables: {
        id,
        status,
      },
      optimisticResponse: {
        updateDraftOutboundDelivery: {
          clientMutationId: null,
          draftOutboundDelivery: {
            id,
            status,
            __typename: 'DraftOutboundDelivery',
          },
          __typename: 'UpdateDraftOutboundDeliveryPayload',
        },
      },
    })
    db.deliveries.update(id, {
      status,
    })
  }

  const duplicate = (delivery) => {
    const items =
      delivery.draftOutboundDeliveryItemsByIdDraftOutboundDelivery?.nodes || delivery.products
    for (let index = 0; index < items.length; index++) {
      const element = items[index]
      element.delivered = ''
      element.quantity = null
    }

    db.offlineOrders.add({
      signature: '',
      process: 'validate',
      customer: delivery.saleOrderClientName,
      customerId: delivery.idClient,
      plate: delivery.truckLicencePlate,
      constructionNb: delivery.constructionSiteCode,
      address: delivery.constructionSiteAddress,
      city: delivery.constructionSiteCity,
      destinationSite: delivery.destinationSite,
      forwarder: delivery.forwardingAgent,
      externalReference: delivery.externalReference,
      outMaterials: items.filter(
        (e) => e.materialByIdMaterial.productCategoryByIdProductCategory.family === 'MATERIAL'
      ),
      inMaterials: items.filter(
        (e) => e.materialByIdMaterial.productCategoryByIdProductCategory.family === 'RUBBLE'
      ),
    })
    if (delivery.idInternalTransfert) {
      history.push('/evacuation')
    } else {
      history.push('/summary')
    }
  }

  return (
    <div>
      <Header title={t('history')} />
      <div className="history">
        <div className="history-content">
          <div className="history-filter">
            <p>{t('filter')}</p>
            <select value={period} onChange={(e) => setPeriod(e.target.value)}>
              <option value={0}>{t('today')}</option>
              <option value={1}>{t('yesterday')}</option>
              <option value={2}>{t('lastWeek')}</option>
              {/* <option value={3}>
                    {t('lastMonth')}
                  </option> */}
            </select>
            <input
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="history-item-content">
            {loading ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div
                  style={{
                    height: '25vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '20%',
                  }}
                >
                  <div className="ring" style={{ fontWeight: 'bolder' }} />
                  {t('delivery')}
                </div>
              </div>
            ) : (
              <p>
                {syncTime?.date &&
                  deliveries?.map((delivery) => (
                    <HistoryItem
                      synced={
                        new Date(delivery.createdAt).getTime() <
                          new Date(syncTime.date).getTime() || network
                      }
                      status={delivery.status}
                      key={delivery.id}
                      customer={delivery.saleOrderClientName}
                      products={
                        delivery.draftOutboundDeliveryItemsByIdDraftOutboundDelivery?.nodes ||
                        delivery.products
                      }
                      plateNumber={delivery.truckLicencePlate}
                      onDuplicate={() => duplicate(delivery)}
                      report={() =>
                        report(delivery.id, delivery.status === 'VALIDER' ? 'REDRESSER' : 'VALIDER')
                      }
                    />
                  ))}
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer>
        <Button
          text={t('back')}
          appearance="secondary"
          type="outline"
          width="210px"
          onClick={() => history.push('/endPending')}
        />
      </Footer>
    </div>
  )
}

export default History
