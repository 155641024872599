import PropTypes from 'prop-types'
import React from 'react'
import './FooterStyle.scss'

const Footer = ({ children }) => (
  <div className="footer">
    <div className="footer-fixed">{children}</div>
  </div>
)

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Footer
