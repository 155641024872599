/* eslint-disable no-param-reassign */
import { useLiveQuery } from 'dexie-react-hooks'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Footer, Header, ProductList, TableTab } from '../../components'
import { CREATE_FAV_MATERIAL, DELETE_FAV_MATERIAL } from '../../gql'
import { db } from '../../lib/db'
import './ProductPageStyle.scss'

const ProductPage = () => {
  const { search } = window.location
  const query = new URLSearchParams(search)
  const { t } = useTranslation('productsPage')
  const history = useHistory()
  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())

  const [selectedTab, setSelectedTab] = useState(
    query.get('tab') === 'in' ? t('wastes') : t('materials')
  )
  const [checkedOut, setCheckedOut] = useState([])
  const [checkedIn, setCheckedIn] = useState([])
  const [products, setProducts] = useState([])
  const [validating, setValidating] = useState(false)

  useEffect(() => {
    if (lastOrder) {
      setCheckedOut(lastOrder?.outMaterials ? [...lastOrder?.outMaterials] : [])
      setCheckedIn(lastOrder?.inMaterials ? [...lastOrder?.inMaterials] : [])
    }
  }, [lastOrder])

  const [createFav] = useMutation(CREATE_FAV_MATERIAL)
  const [deleteFav] = useMutation(DELETE_FAV_MATERIAL)

  const materials = useLiveQuery(async () => {
    const cats = await db.material.toArray()
    setProducts(cats)
    return cats
  }, [])

  const rubbles = useLiveQuery(async () => {
    const cats = await db.rubble.toArray()
    return cats
  }, [])

  const favorites = useLiveQuery(async () => {
    const favs = await db.favorites.toArray()
    return favs
  }, [])

  const addToFavorite = (id) => {
    createFav({
      variables: {
        idMaterial: id,
        idUser: jwtDecode(localStorage.getItem('access_token')).id,
      },
    })
    db.favorites.add({ id })
  }

  const deleteFavorite = (id) => {
    deleteFav({
      variables: {
        idMaterial: id,
        idUser: jwtDecode(localStorage.getItem('access_token')).id,
      },
    })
    db.favorites.delete(id)
  }

  useEffect(() => {
    setProducts(selectedTab === t('materials') ? materials : rubbles)
  }, [selectedTab])

  const checkProducts = (product) => {
    if (selectedTab === t('materials')) {
      if (
        checkedOut.findIndex(
          (e) => e.materialByIdMaterial?.id === product.id || e.matId === product.id
        ) !== -1
      ) {
        const index = checkedOut.findIndex(
          (e) => e.materialByIdMaterial?.id === product.id || e.matId === product.id
        )
        checkedOut.splice(index, 1)
        setCheckedOut([...checkedOut])
      } else {
        checkedOut.push({ quantity: 0, materialByIdMaterial: product })
        setCheckedOut([...checkedOut])
      }
    } else if (
      checkedIn.findIndex(
        (e) => e.materialByIdMaterial.id === product.id || e.matId === product.id
      ) !== -1
    ) {
      const index = checkedIn.findIndex(
        (e) => e.materialByIdMaterial.id === product.id || e.matId === product.id
      )
      checkedIn.splice(index, 1)
      setCheckedIn([...checkedIn])
    } else {
      checkedIn.push({ quantity: 0, materialByIdMaterial: product })
      setCheckedIn([...checkedIn])
    }
  }
  const transformData = (checked) => {
    const Array = checked
    Array.map((element, index) => {
      element.id = Array[index]?.materialByIdMaterial?.id || Array[index].matId
      element.description =
        Array[index].materialByIdMaterial?.description || Array[index].description
      return 0
    })
    return Array
  }
  const validate = () => {
    setValidating(true)
    db.offlineOrders.update(lastOrder?.id, {
      outMaterials: transformData(checkedOut),
      inMaterials: transformData(checkedIn),
    })

    if (query.get('process') === 'evacuate') {
      history.push('/evacuation')
    } else {
      history.push('/summary')
    }
  }

  return (
    <div>
      <Header title={t('productCatalog')} />
      <div className="product-page">
        <TableTab
          tabs={[t('materials'), t('wastes')]}
          selectedTab={selectedTab}
          onSelect={(tab) => setSelectedTab(tab)}
        />
        {products && products.length > 0 && (
          <ProductList
            favorites={favorites}
            addToFavorite={(id) => addToFavorite(id)}
            deleteFavorite={(id) => deleteFavorite(id)}
            products={products}
            checkedProducts={selectedTab === t('materials') ? checkedOut : checkedIn}
            onCheck={(product) => checkProducts(product)}
          />
        )}
      </div>
      <Footer>
        <Button
          text={t('back')}
          type="outline"
          appearance="secondary"
          width="32%"
          onClick={() => history.push('/orders/new')}
        />
        <Button
          text={t('quit')}
          type="outline"
          appearance="secondary"
          width="32%"
          onClick={() => history.push('/orders')}
        />
        <Button text={t('validate')} width="32%" loading={validating} onClick={() => validate()} />
      </Footer>
    </div>
  )
}

export default ProductPage
