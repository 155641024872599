import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderTableItem } from '../index'
import './OrderTableStyle.scss'

const OrderTable = ({ outProducts, inProducts, updateValue }) => {
  const { t } = useTranslation('orderSummary')
  return (
    <table className="order-table">
      <thead>
        <tr>
          <th>{t('products')}</th>
          <th>{t('left')}</th>
          <th>{t('delivered')}</th>
        </tr>
      </thead>
      <tbody>
        {outProducts?.map((product) => (
          <OrderTableItem
            key={product.id}
            toSend={true}
            product={product}
            quantity={product.quantity}
            updateValue={(a, b, c) => updateValue(a, b, c)}
          />
        ))}
      </tbody>
      <tbody>
        {inProducts?.map((product) => (
          <OrderTableItem
            key={product.id}
            product={product}
            updateValue={(a, b, c) => updateValue(a, b, c)}
          />
        ))}
      </tbody>
    </table>
  )
}

OrderTable.propTypes = {
  outProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  inProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateValue: PropTypes.func.isRequired,
}

export default OrderTable
