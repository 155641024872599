import Dexie from 'dexie'

export const db = new Dexie('bdn')
db.version(1).stores({
  clients: '&id, &displayName',
  sales: '&id, clientId',
  rubble: '&id, &description',
  material: '&id, &description',
  lastSync: '&id, date',
  offlineOrders: '++id, completed',
})

db.version(2).stores({
  deliveries: '&id',
})

db.version(3).stores({
  forwarders: '&id, &displayName',
})

db.version(4).stores({
  favorites: '&id',
})
