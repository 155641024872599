import PropTypes from 'prop-types'
import React from 'react'
import './TableTopStyle.scss'

const TableTop = ({ children }) => (
  <div className="tabletop-container">
    <div className="tabletop">{children}</div>
  </div>
)

TableTop.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableTop
