import { useLiveQuery } from 'dexie-react-hooks'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { Button, Footer, Header } from '../../components'
import { GET_SIGNATURE } from '../../gql'
import { db } from '../../lib/db'
import './SignatureStyle.scss'

const Signature = () => {
  const { t } = useTranslation('signature')
  const { data: signData } = useQuery(GET_SIGNATURE)
  const [error, setError] = useState(false)
  const [validating, setValidating] = useState(false)
  const history = useHistory()
  const { search } = window.location
  const query = new URLSearchParams(search)
  let sigPad = {}

  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())

  useEffect(() => {
    if (signData?.signature) {
      sigPad.fromDataURL(signData?.signature)
    }
  }, [])

  const back = () => {
    db.offlineOrders.update(lastOrder?.id, {
      signature: '',
    })
    if (query.get('process') === 'evacuate') {
      history.push('/evacuation')
    } else {
      history.push('/summary')
    }
  }

  const validate = () => {
    if (!sigPad.isEmpty()) {
      setValidating(true)
      db.offlineOrders.update(lastOrder.id, {
        signature: sigPad.getTrimmedCanvas().toDataURL('image/png'),
      })
      if (query.get('process') === 'evacuate') {
        history.push('/final?process=evacuate')
      } else {
        history.push('/final')
      }
    } else {
      setError(true)
    }
  }

  const redirect = () => {
    if (!sigPad.isEmpty()) {
      db.offlineOrders.update(lastOrder.id, {
        signature: sigPad.getTrimmedCanvas().toDataURL('image/png'),
      })
    }
    if (query.get('process') === 'evacuate') {
      history.push('/final?view=true')
    } else {
      history.push('/final?process=evacuate&view=true')
    }
  }

  return (
    <div>
      <Header title={t('signature')} />
      <div className="signature">
        <div className="sign-canvas">
          <SignatureCanvas
            canvasProps={{
              width: window.innerWidth - 100,
              height: window.innerHeight - 250,
              className: 'sigCanvas',
            }}
            ref={(ref) => {
              sigPad = ref
            }}
          />
        </div>
        {error && <p className="error">{t('error')}</p>}
      </div>
      <Footer>
        <Button
          text={t('back')}
          type="outline"
          appearance="secondary"
          width="24%"
          onClick={() => back()}
        />
        <Button
          text={t('clear')}
          width="24%"
          type="outline"
          appearance="secondary"
          onClick={() => sigPad.clear()}
        />
        <Button
          text={t('view')}
          type="outline"
          appearance="secondary"
          width="24%"
          onClick={() => redirect()}
        />
        <Button text={t('validate')} width="24%" loading={validating} onClick={() => validate()} />
      </Footer>
    </div>
  )
}

export default Signature
