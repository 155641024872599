import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { InputModal } from '../index'
import './InputSimpleStyle.scss'

const InputSimple = ({ value, placeholder, type, required, onChange, autoComplete }) => {
  const [modal, setModal] = useState(false)
  const [error, setError] = useState(false)

  return (
    <>
      <div className="simple-input" role="button" onClick={() => setModal(true)}>
        <input
          className={`${value ? 'success' : 'empty'} ${error ? 'error' : ''}`}
          type={type}
          value={value}
          placeholder={placeholder}
        />
      </div>
      {modal && (
        <InputModal
          value={value}
          placeholder={placeholder}
          type
          setValue={(content) => {
            onChange(content)
            if (!content && required) {
              setError(true)
            } else {
              setError(false)
            }
          }}
          closeModal={() => setModal(false)}
          autoCompleteList={autoComplete}
        />
      )}
    </>
  )
}

InputSimple.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.arrayOf(PropTypes.string),
}

InputSimple.defaultProps = {
  value: '',
  required: false,
  autoComplete: null,
  placeholder: '',
}

export default InputSimple
