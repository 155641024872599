/* eslint-disable max-len */
import * as Sentry from '@sentry/react'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from '../../components'
import IconNetwork from '../../components/icons/IconNetwork/IconNetwork'
import useNetwork from '../../hooks/useNetwork'

import {
  GET_CATEGORIES,
  GET_CLIENTS,
  GET_DELIVERIES,
  GET_FAV_MATERIALS,
  GET_FORWARDERS,
  GET_USER,
} from '../../gql'
import { db } from '../../lib/db'
import Check from './Check.png'
import Fail from './Fail.png'

const Loader = () => {
  const { t } = useTranslation('home')
  const history = useHistory()

  const [clientFetch, setClientFetch] = useState(0)
  const [salesFetch, setSalesFetch] = useState(0)
  const [deliveryFetch, setDeliveryFetch] = useState(0)
  const [userFetch, setUserFetch] = useState(0)
  const [catFetch, setCatFetch] = useState(0)
  const [favFetch, setFavFetch] = useState(0)
  const [forwarderFetch, setForwarderFetch] = useState(0)

  const [clientCount, setClientCount] = useState(0)
  const [deliveryCount, setDeliveryCount] = useState(0)
  const [salesCount, setSalesCount] = useState(0)
  const [catCount, setCatCount] = useState(0)
  const [favCount, setFavCount] = useState(0)
  const [forwarderCount, setForwarderCount] = useState(0)
  const network = useNetwork()

  const [clientError, setClientError] = useState(false)
  const [salesError, setSalesError] = useState(false)
  const [deliveryError, setDeliveryError] = useState(false)
  const [userError, setUserError] = useState(false)
  const [catError, setCatError] = useState(false)
  const [favError, setFavError] = useState(0)
  const [forwarderError, setForwarderError] = useState(false)

  const [clientFetched, setClientFetched] = useState(false)
  const [salesFetched, setSalesFetched] = useState(false)
  const [deliveryFetched, setDeliveryFetched] = useState(false)
  const [userFetched, setUserFetched] = useState(false)
  const [catFetched, setCatFetched] = useState(false)
  const [favFetched, setFavFetched] = useState(false)
  const [forwarderFetched, setForwarderFetched] = useState(false)

  useEffect(() => {
    if (
      clientFetch === clientCount &&
      clientFetched &&
      salesFetch === salesCount &&
      salesFetched &&
      deliveryFetch === deliveryCount &&
      deliveryFetched &&
      userFetch > 0 &&
      userFetched &&
      catFetch === catCount &&
      catFetched &&
      favFetch === favCount &&
      favFetched &&
      forwarderFetch === forwarderCount &&
      forwarderFetched
    ) {
      db.lastSync.put({ id: 1, date: moment().format('DD/MM/YY HH:mm') }, 1)
      history.push('/endPending')
    }
  }, [
    clientFetch,
    salesFetch,
    deliveryFetch,
    userFetch,
    catFetch,
    forwarderFetch,
    clientCount,
    salesCount,
    catCount,
    forwarderCount,
    clientFetched,
    salesFetched,
    deliveryFetched,
    userFetched,
    catFetched,
    forwarderFetched,
  ])

  const addSales = (sales, clt) => {
    db.sales
      .add({
        id: sales.id,
        code: sales.code,
        externalReference: sales.externalReference,
        siteByIdSite: {
          id: sales.siteByIdSite.id,
          name: sales.siteByIdSite.name,
        },
        materials: [
          ...sales.salesOrderItemsByIdSalesOrder.nodes.map((mat) => ({
            quantity: mat.quantity,
            matId: mat.materialByIdMaterial.id,
            description: mat.materialByIdMaterial.description,
            family: mat.materialByIdMaterial.productCategoryByIdProductCategory.family,
          })),
        ],
        clientId: clt.node.id,
      })
      .then(
        () => {
          setSalesFetch((prev) => prev + 1)
        },
        () => {
          setSalesFetch((prev) => prev + 1)
        }
      )
  }

  const addClient = (clt) => {
    db.clients
      .put(
        {
          id: clt.node.id,
          active: clt.node.active,
          displayName: clt.node.displayName,
          internalId: clt.node.internalId,
        },
        clt.node.id
      )
      .then(
        () => {
          setClientFetch((prev) => prev + 1)
        },
        () => {
          setClientFetch((prev) => prev + 1)
        }
      )
  }

  const { data: userData } = useQuery(GET_USER, {
    onCompleted: () => {
      setUserFetched(true)
      setUserFetch(userFetch + 1)
    },
    onError: (error) => {
      setUserError(true)
      Sentry.captureException(error)
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: localStorage.getItem('access_token')
        ? jwtDecode(localStorage.getItem('access_token')).id
        : '',
    },
  })

  const [fetchClients] = useLazyQuery(GET_CLIENTS, {
    onCompleted: async (clients) => {
      db.clients.clear().finally(() => {
        setClientFetched(true)
        setClientCount((prev) => prev + clients?.clients.edges.length)
        clients?.clients.edges.forEach((clt) => {
          db.sales.delete(clt.id).then(
            () => {
              addClient(clt)
            },
            () => {
              addClient(clt)
            }
          )

          setSalesFetched(true)
          setSalesCount((prev) => prev + clt.node.salesOrdersByIdClient.nodes.length)
          clt.node.salesOrdersByIdClient.nodes.forEach((sales) => {
            db.sales.delete(sales.id).then(
              () => {
                addSales(sales, clt)
              },
              () => {
                addSales(sales, clt)
              }
            )
          })
        })
      })
    },
    onError: (error) => {
      setSalesError(true)
      setClientError(true)
      Sentry.captureException(error)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      idSite: userData?.user?.idSite,
      greaterThan: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    },
  })

  const addRubble = (material) => {
    db.rubble
      .put(
        {
          id: material.id,
          description: material.description,
        },
        material.id
      )
      .then(
        () => {
          setCatFetch((prev) => prev + 1)
        },
        () => {
          setCatFetch((prev) => prev + 1)
        }
      )
  }

  useQuery(GET_CATEGORIES, {
    onCompleted: (cats) => {
      cats?.productCategories.nodes.forEach((cat) => {
        cat.materialsByIdProductCategory.nodes.forEach((materialsByIdProductCategory) => {
          setCatFetched(true)
          setCatCount(
            (prev) => prev + materialsByIdProductCategory.siteMaterialsByIdMaterial.nodes.length
          )
          materialsByIdProductCategory.siteMaterialsByIdMaterial.nodes.forEach(
            (materialByIdMaterial) => {
              const material = materialByIdMaterial.materialByIdMaterial
              db.rubble.delete(material.id).then(
                () => {
                  addRubble(material)
                },
                () => {
                  addRubble(material)
                }
              )
            }
          )
        })
      })
    },
    onError: (error) => {
      setCatError(true)
      Sentry.captureException(error)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      idSite: userData?.user?.idSite,
      family: 'RUBBLE',
    },
  })

  const addForwarder = (fw) => {
    db.forwarders
      .put(
        {
          id: fw.id,
          displayName: fw.displayName,
        },
        fw.id
      )
      .then(
        () => {
          setForwarderFetch((prev) => prev + 1)
        },
        () => {
          setForwarderFetch((prev) => prev + 1)
        }
      )
  }

  useQuery(GET_FORWARDERS, {
    onCompleted: (forwarders) => {
      db.forwarders.clear().finally(() => {
        setForwarderFetched(true)
        setForwarderCount((prev) => prev + forwarders?.freightForwarders.nodes.length)
        forwarders?.freightForwarders.nodes.forEach((fw) => {
          db.forwarders.delete(fw.id).then(
            () => {
              addForwarder(fw)
            },
            () => {
              addForwarder(fw)
            }
          )
        })
      })
    },
    onError: (error) => {
      setForwarderError(true)
      Sentry.captureException(error)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const addMaterial = (material) => {
    db.material
      .put(
        {
          id: material.id,
          description: material.description,
        },
        material.id
      )
      .then(
        () => {
          setCatFetch((prev) => prev + 1)
        },
        () => {
          setCatFetch((prev) => prev + 1)
        }
      )
  }

  const addDelivery = (delivery) => {
    const bound = delivery
    const products = delivery.draftOutboundDeliveryItemsByIdDraftOutboundDelivery?.nodes
    delete bound.draftOutboundDeliveryItemsByIdDraftOutboundDelivery
    db.deliveries.put({ ...bound, products }, delivery.id).then(
      () => {
        setDeliveryFetch((prev) => prev + 1)
      },
      () => {
        setDeliveryFetch((prev) => prev + 1)
      }
    )
  }

  const addFavorite = (favorite) => {
    db.favorites.put({ id: favorite.id }, favorite.id).then(
      () => {
        setFavFetch((prev) => prev + 1)
      },
      () => {
        setFavFetch((prev) => prev + 1)
      }
    )
  }

  useQuery(GET_CATEGORIES, {
    onCompleted: (cats) => {
      cats?.productCategories.nodes.forEach((cat) => {
        cat.materialsByIdProductCategory.nodes.forEach((materialsByIdProductCategory) => {
          setCatFetched(true)
          setCatCount(
            (prev) => prev + materialsByIdProductCategory.siteMaterialsByIdMaterial.nodes.length
          )
          materialsByIdProductCategory.siteMaterialsByIdMaterial.nodes.forEach(
            (materialByIdMaterial) => {
              const material = materialByIdMaterial.materialByIdMaterial
              db.rubble.delete(material.id).then(
                () => {
                  addMaterial(material)
                },
                () => {
                  addMaterial(material)
                }
              )
            }
          )
        })
      })
    },
    onError: (error) => {
      setCatError(true)
      Sentry.captureException(error)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      idSite: userData?.user?.idSite,
      family: 'MATERIAL',
    },
  })

  const [fetchFavorites] = useLazyQuery(GET_FAV_MATERIALS, {
    onCompleted: (favorites) => {
      favorites?.favMaterials?.nodes.forEach((favorite) =>
        addFavorite(favorite?.materialByIdMaterial)
      )
      setFavFetched(true)
      setFavCount(favorites?.favMaterials?.nodes?.length)
    },
    onError: (error) => {
      setFavError(true)
      Sentry.captureException(error)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: userData?.user?.id,
    },
  })

  const [fetchDeliveries] = useLazyQuery(GET_DELIVERIES, {
    onCompleted: (deliveries) => {
      db.deliveries.clear().finally(() => {
        deliveries?.draftOutboundDeliveries?.nodes.forEach((delivery) => addDelivery(delivery))
        setDeliveryFetched(true)
        setDeliveryCount(deliveries?.draftOutboundDeliveries?.nodes?.length)
      })
    },
    onError: (error) => {
      setDeliveryError(true)
      Sentry.captureException(error)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      site: userData?.user?.idSite,
      startDate: moment().subtract(1, 'day').format('yyyy-MM-DD'),
      endDate: moment().add(1, 'day').format('yyyy-MM-DD'),
    },
  })

  useEffect(() => {
    if (userData) {
      fetchDeliveries()
      fetchClients()
      fetchFavorites()
    }
  }, [userData])

  const styles = {
    container: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
    network: { display: 'flex', justifyContent: 'end', width: '100%', padding: '20px 50px' },
    infos: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '20%',
      marginBottom: '20px',
    },
    infosError: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '20%',
      marginBottom: '20px',
      color: '#F00',
    },
    image: { height: '30px', marginRight: '15px' },
    ring: { fontWeight: 'bolder' },
    error: {
      color: '#F00',
      fontSize: '18px',
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      marginTop: '20px',
    },
  }

  const pictoLoading = (fetch, error) => {
    if (error) {
      return <img style={styles.image} src={Fail} alt="Terminer" />
    }

    return fetch >= 1 ? (
      <img style={styles.image} src={Check} alt="Terminer" />
    ) : (
      <div className="ring small" style={styles.ring} />
    )
  }

  const gotoHomePage = () => {
    history.push('/endPending')
  }

  return (
    <div style={styles.container}>
      <div style={styles.network}>
        <IconNetwork active={network} />
      </div>
      <div style={userError ? styles.infosError : styles.infos}>
        {pictoLoading(userFetch >= 1 && userFetched, userError)}
        {t('user')}
      </div>
      <div style={catError ? styles.infosError : styles.infos}>
        {pictoLoading(catFetch === catCount && catFetched, catError)}
        {t('cat')}
        {catCount > 0 && t('loadingCount', { count: catFetch, total: catCount })}
      </div>
      <div style={favError ? styles.infosError : styles.infos}>
        {pictoLoading(favFetch === favCount && clientFetched, favError)}
        {t('fav')}
        {favCount > 0 && t('loadingCount', { count: favFetch, total: favCount })}
      </div>
      <div style={deliveryError ? styles.infosError : styles.infos}>
        {pictoLoading(deliveryFetch === deliveryCount && deliveryFetched, deliveryError)}
        {t('delivery')}
        {deliveryCount > 0 && t('loadingCount', { count: deliveryFetch, total: deliveryCount })}
      </div>
      <div style={clientError ? styles.infosError : styles.infos}>
        {pictoLoading(clientFetch === clientCount && clientFetched, clientError)}
        {t('client')}
        {clientCount > 0 && t('loadingCount', { count: clientFetch, total: clientCount })}
      </div>
      <div style={salesError ? styles.infosError : styles.infos}>
        {pictoLoading(salesFetch === salesCount && salesFetched, salesError)}
        {t('sales')}
        {salesCount > 0 && t('loadingCount', { count: salesFetch, total: salesCount })}
      </div>
      <div style={forwarderError ? styles.infosError : styles.infos}>
        {pictoLoading(forwarderFetch === forwarderCount && forwarderFetched, forwarderError)}
        {t('forwarder')}
        {forwarderCount > 0 && t('loadingCount', { count: forwarderFetch, total: forwarderCount })}
      </div>

      {(clientError || salesError || deliveryError || userError || catError) && (
        <>
          <p style={styles.error}>{t('loadingError')}</p>
          <div style={{ margin: '10px auto' }}>
            <Button text="Retourner à la page d'accueil" type="outline" onClick={gotoHomePage} />
          </div>
        </>
      )}
    </div>
  )
}

export default Loader
