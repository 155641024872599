import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { InputModal } from '../index'
import './CellInputStyle.scss'

const CellInput = ({ value, onChange }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <div role="button" onClick={() => setModal(true)} className="cell-input">
        <input type="number" value={value} />
      </div>
      {modal && (
        <InputModal
          type="number"
          value={value}
          setValue={(content) => {
            onChange(content)
          }}
          closeModal={() => setModal(false)}
        />
      )}
    </>
  )
}

CellInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

CellInput.defaultProps = {
  value: '',
}

export default CellInput
