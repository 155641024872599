import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import api from '../../api'
import { Button, Header } from '../../components'
import { GET_USER } from '../../gql'
import './WaitingPageStyle.scss'

const WaitingPage = () => {
  const { t } = useTranslation('waitingPage')
  const history = useHistory()
  const token = localStorage.getItem('access_token')

  const [actualSeed, setActualSeed] = useState()

  const [getUser] = useLazyQuery(GET_USER)

  const sendSeed = async () => {
    localStorage.removeItem('seed')
    localStorage.removeItem('idDevice')
    const seed = uuid()
    setActualSeed(seed)
    const res = await api.sendSeed(seed)
    localStorage.setItem('seed', seed)
    localStorage.setItem('idDevice', res.id)
  }

  const checkIfVerified = async () => {
    const seed = localStorage.getItem('seed')
    const idDeviceConnection = localStorage.getItem('idDevice')
    const res = await api.checkIfVerified(idDeviceConnection, seed)
    if (res.access_token) {
      localStorage.setItem('access_token', res.access_token)
      localStorage.setItem('refresh_token', res.refresh_token)
      getUser({
        variables: {
          id: jwtDecode(localStorage.getItem('access_token')).id,
        },
      })
      history.push('/')
    }
  }

  useEffect(() => {
    if (!localStorage.getItem('idDevice') && !localStorage.getItem('seed')) {
      sendSeed()
    } else if (!token) {
      checkIfVerified()
    } else {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    const seed = localStorage.getItem('seed')
    if (seed) {
      setActualSeed(seed)
    }
  }, [])

  return (
    <div>
      <Header title={t('bdn')} />
      <div className="pending">
        <h1>{t('wait')}</h1>
        <p className="code">
          {t('tabletCode')} {actualSeed?.slice(0, 7)}
        </p>
        <div className="buttons">
          <Button text={t('resend')} onClick={() => sendSeed()} />
          <Button text={t('refresh')} onClick={() => checkIfVerified()} />
        </div>
      </div>
    </div>
  )
}

export default WaitingPage
