import * as Sentry from '@sentry/react'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import { useHistory } from 'react-router-dom'

import { v4 as uuid } from 'uuid'
import api from '../../api'
import client from '../../client'
import useNetwork from '../../hooks/useNetwork'

import {
  CREATE_DELIVERIES,
  CREATE_DELIVERIY_ITEMS,
  GET_CONSTRUCTION_SITE_AND_TRUCK,
  GET_DELIVERIES,
  GET_DEVICE_VALIDATION,
  GET_SITE_BY_ID,
  GET_USER,
  UPDATE_DELIVERY,
  UPDATE_STATUS,
} from '../../gql'
import { convertDataURIToBinary, isUserTokenExpired } from '../../lib/helpers'
import { captureMessageWithAttachment } from '../../lib/sentry'

import config from '../../config'
import { db } from '../../lib/db'

const FooterSite = () => {
  const history = useHistory()

  const [errorOnSendingSentry, setErrorOnSendingSentry] = useState(false)
  const [currentOrder, setCurrentOrder] = useState()
  const [seed, setSeed] = useState()
  const network = useNetwork()

  const [fetchUserData, { data: userData }] = useLazyQuery(GET_USER)

  const [fetchSiteCode, { data: siteCode }] = useLazyQuery(GET_SITE_BY_ID, {
    variables: {
      id: userData?.user?.idSite,
    },
  })

  const [fetchDeliveries, { data: historyData }] = useLazyQuery(GET_DELIVERIES, {
    variables: {
      site: userData?.user?.idSite,
      startDate: moment().subtract(1, 'week').format('yyyy-MM-DD'),
      endDate: moment().add(1, 'day').format('yyyy-MM-DD'),
    },
  })

  const { refetch: refetchDevice, data: deviceData } = useQuery(GET_DEVICE_VALIDATION, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      idDeviceSeed: localStorage.getItem('idDevice'),
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  const sendSignaturesAfterGetBackOnline = async () => {
    const accessToken = localStorage.getItem('access_token')
    if (currentOrder) {
      const { graphqlDeliveryId, signature } = currentOrder
      const binary = convertDataURIToBinary(signature)
      const blob = new Blob([binary], { type: 'image/png' })
      try {
        await api.uploadFile(graphqlDeliveryId, blob, accessToken, 'signature')
        db.offlineOrders.delete(currentOrder.id).then(() => {
          // eslint-disable-next-line no-use-before-define
          syncDraftDeliveries()
        })
      } catch (e) {
        console.log('error sending signature when back online')
      }
    }
  }

  const checkIfAllIsCreated = () => {
    let count = 0
    currentOrder?.outMaterials.forEach((materials) => {
      if (materials.graphqlId) {
        count += 1
      }
    })

    currentOrder?.inMaterials.forEach((materials) => {
      if (materials.graphqlId) {
        count += 1
      }
    })

    if (currentOrder?.outMaterials?.length + currentOrder?.inMaterials?.length === count) {
      sendSignaturesAfterGetBackOnline()
    }
  }

  const [updateStatus] = useMutation(UPDATE_STATUS)

  const [createDeliveryMaterial] = useMutation(CREATE_DELIVERIY_ITEMS, {
    onCompleted: (d) => {
      currentOrder?.outMaterials.forEach((m) => {
        const material = m
        try {
          const { idMaterial } = d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem
          const matId = material.id || material.materialByIdMaterial?.id
          if (matId === idMaterial || material.matId === idMaterial) {
            material.graphqlId = d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.id
          }
        } catch (e) {
          console.log(e)
        }
      })

      currentOrder?.inMaterials.forEach((m) => {
        const material = m
        try {
          const { idMaterial } = d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem
          const matId = material.id || material.materialByIdMaterial?.id
          if (matId === idMaterial || material.matId === idMaterial) {
            material.graphqlId = d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.id
          }
        } catch (e) {
          console.log(e)
        }
      })

      db.offlineOrders.update(currentOrder?.id, currentOrder).then(() => {
        checkIfAllIsCreated()
      })
    },
    onError: (err) => {
      const request = CREATE_DELIVERIY_ITEMS
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          idDraftOutboundDelivery: seed,
          materials: currentOrder?.outMaterials,
        },
      }
      captureMessageWithAttachment(event, err)
      updateStatus({
        variables: {
          id: seed || currentOrder.graphqlDeliveryId,
          status: 'REDRESSER',
        },
      })

      sendSignaturesAfterGetBackOnline()
    },
  })

  const createDeliveryItems = () => {
    if (currentOrder?.outMaterials?.length > 0) {
      for (let index = 0; index < currentOrder?.outMaterials?.length; index++) {
        const element = currentOrder?.outMaterials[index]
        const id = uuid()
        const matId = element.id || element.materialByIdMaterial?.id
        if (element.delivered && !element.graphqlId) {
          createDeliveryMaterial({
            variables: {
              id,
              idDraftOutboundDelivery: seed || currentOrder.graphqlDeliveryId,
              idMaterial: matId || element.matId,
              quantity: element.delivered,
            },
          })
        }
      }
    }
    if (currentOrder?.inMaterials?.length > 0) {
      for (let index = 0; index < currentOrder?.inMaterials?.length; index++) {
        const element = currentOrder?.inMaterials[index]
        const id = uuid()
        const matId = element.id || element.materialByIdMaterial?.id
        if (element.delivered && !element.graphqlId) {
          createDeliveryMaterial({
            variables: {
              id,
              idDraftOutboundDelivery: seed || currentOrder.graphqlDeliveryId,
              idMaterial: matId || element.matId,
              quantity: element.delivered,
            },
          })
        }
      }
    }
    checkIfAllIsCreated()
  }

  const [updateDelivery] = useMutation(UPDATE_DELIVERY, {
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  const [fetchConstructionSiteAndTruck] = useLazyQuery(GET_CONSTRUCTION_SITE_AND_TRUCK, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response.trucks?.nodes?.length > 0) {
        updateDelivery({
          variables: {
            id: currentOrder.graphqlDeliveryId,
            idTruck: response?.trucks?.nodes[0]?.id,
          },
        })
      }

      if (response.constructionSiteByCode?.id) {
        updateDelivery({
          variables: {
            id: currentOrder.graphqlDeliveryId,
            idConstructionSite: response?.constructionSiteByCode?.id,
            constructionSiteCity: response?.constructionSiteByCode?.city,
            constructionSiteAddress: response?.constructionSiteByCode?.address,
          },
        })
      }

      createDeliveryItems()
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  const [newDelivery] = useMutation(CREATE_DELIVERIES, {
    onCompleted: (data) => {
      currentOrder.graphqlDeliveryId = data?.createDraftOutboundDelivery?.draftOutboundDelivery?.id
      db.offlineOrders
        .update(currentOrder?.id, {
          graphqlDeliveryId: data?.createDraftOutboundDelivery?.draftOutboundDelivery?.id,
        })
        .then(() => {
          fetchConstructionSiteAndTruck({
            variables: {
              plicensePlate: currentOrder?.plate || '',
              code: currentOrder?.constructionNb || '',
            },
          })
        })
    },
    onError: (err) => {
      const request = CREATE_DELIVERIES
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: seed,
          idSite: userData?.user?.idSite,
          idSaleOrder: currentOrder?.orderId || null,
          idConstructionSite: currentOrder?.constructionId || null,
          idTruck: currentOrder?.plate || null,
          constructionSiteAddress: currentOrder?.address || '',
          constructionSiteCity: currentOrder?.city || '',
          constructionSiteCode: currentOrder?.constructionNb || '',
          truckLicencePlate: currentOrder?.plate || '',
          saleOrderClientName: currentOrder?.customer || '',
          idClient: currentOrder?.customerId || null,
          value: moment().format(),
          idInternalTransfert:
            currentOrder?.process === 'evacuate' ? config.idInternalTransfert : null,
          code: currentOrder?.deliveryCode,
          forwardingAgent: currentOrder?.forwarder || '',
          idFreightForwarder: currentOrder.idFreightForwarder || null,
          externalReference: currentOrder?.externalReference || '',
          destinationSite: currentOrder?.destinationSite || '',
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const checkIfVerified = () => {
    if (
      deviceData?.deviceByIdDeviceSeed?.isValid === false ||
      deviceData?.deviceByIdDeviceSeed === null
    ) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('seed')
      localStorage.removeItem('idDevice')
      history.push('/pending')
    }
  }

  useEffect(() => {
    if (currentOrder && network) {
      if (currentOrder.graphqlDeliveryId) {
        // BL is already created. some materials are'nt created
        createDeliveryItems()
      } else {
        // need to create a new one
        const newSeed = uuid()
        setSeed(newSeed)
        newDelivery({
          variables: {
            id: newSeed,
            idSite: userData?.user?.idSite,
            idSaleOrder: currentOrder?.orderId || null,
            idConstructionSite: currentOrder?.constructionId || null,
            idTruck: currentOrder?.plate || null,
            constructionSiteAddress: currentOrder?.address || '',
            constructionSiteCity: currentOrder?.city || '',
            constructionSiteCode: currentOrder?.constructionNb || '',
            truckLicencePlate: currentOrder?.plate || '',
            saleOrderClientName: currentOrder?.customer || '',
            idClient: currentOrder?.customerId || null,
            value: moment().format(),
            idInternalTransfert:
              currentOrder?.process === 'evacuate' ? config.idInternalTransfert : null,
            code: currentOrder?.deliveryCode,
            forwardingAgent: currentOrder?.forwarder || '',
            idFreightForwarder: currentOrder.idFreightForwarder || null,
            externalReference: currentOrder?.externalReference || '',
            destinationSite: currentOrder?.destinationSite || '',
          },
        })
      }
    }
  }, [currentOrder])

  const syncDraftDeliveries = async () => {
    const order = await db.offlineOrders
      .orderBy('id')
      .filter((o) => o.completed)
      .last()
    setCurrentOrder(order)
  }

  useEffect(() => {
    if (historyData) {
      syncDraftDeliveries()
    }
  }, [network])

  useEffect(() => {
    if (siteCode) {
      fetchDeliveries()
    }
  }, [siteCode])

  useEffect(() => {
    if (userData) {
      fetchSiteCode()
    }
  }, [userData])

  useEffect(() => {
    refetchDevice()
    fetchUserData({
      variables: {
        id: localStorage.getItem('access_token')
          ? jwtDecode(localStorage.getItem('access_token')).id
          : '',
      },
    })
    checkIfVerified()
    syncDraftDeliveries()
  }, [])

  const resync = async () => {
    refetchDevice()
    fetchUserData({
      variables: {
        id: localStorage.getItem('access_token')
          ? jwtDecode(localStorage.getItem('access_token')).id
          : '',
      },
    })
    checkIfVerified()
    const dt = await db.lastSync.toArray()
    const lastSync = dt[0]
    if (
      lastSync?.date &&
      moment(lastSync.date, 'DD/MM/YY HH:mm').add(config.CACHE_TIME, 'minutes') <= moment()
    ) {
      Promise.all([
        db.clients.clear(),
        db.sales.clear(),
        db.rubble.clear(),
        db.material.clear(),
      ]).then(
        () => {
          history.push('/')
        },
        () => {
          history.push('/')
        }
      )
    }
  }

  const sendSentryRequests = () => {
    const getCacheSentry = JSON.parse(localStorage.getItem('sentry'))
    if (getCacheSentry) {
      for (let i = 0; i < getCacheSentry.length; i++) {
        const { data } = getCacheSentry[i]
        const { error } = getCacheSentry[i]
        try {
          captureMessageWithAttachment(data, error)
        } catch (e) {
          setErrorOnSendingSentry(true)
        }
      }
      if (!errorOnSendingSentry) {
        localStorage.removeItem('sentry')
      }
    }
  }

  const handleConnectionChange = (event) => {
    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    if (event.type === 'online') {
      if (accessToken && !isUserTokenExpired(accessToken)) {
        sendSentryRequests()
        resync()
      } else if (refreshToken && !isUserTokenExpired(refreshToken)) {
        api
          .refreshAccessToken(refreshToken)
          .then((response) => {
            localStorage.setItem('access_token', response.access_token)
            localStorage.setItem('refresh_token', response.refresh_token)
          })
          .then(
            () => {
              client.getStoreOffline().process()
            },
            () => {
              console.log('error')
            }
          )
      } else {
        localStorage.clear()
        history.push('/pending')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)
  }, [])

  return <div />
}

export default FooterSite
