/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import api from '../../api'
import { isUserTokenExpired } from '../../lib/helpers'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      let accessToken = localStorage.getItem('access_token')
      const refreshToken = localStorage.getItem('refresh_token')
      if (accessToken && isUserTokenExpired(accessToken)) {
        if (refreshToken && !isUserTokenExpired(refreshToken)) {
          try {
            api.refreshAccessToken(refreshToken).then((resp) => {
              if (resp && resp.accessToken && resp.refreshToken) {
                localStorage.setItem('access_token', resp.access_token)
                localStorage.setItem('refresh_token', resp.refresh_token)
              }
            })
          } catch (error) {
            localStorage.clear()
            return <Redirect to={{ pathname: '/pending' }} />
          }
        } else {
          accessToken = null
          localStorage.clear()
        }
      }
      const redirectPath = accessToken ? '/' : '/pending'
      return accessToken ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: `${redirectPath}` }} />
      )
    }}
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string, PropTypes.any])
    .isRequired,
}

export default PrivateRoute
