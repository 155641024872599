import Tracker from '@openreplay/tracker'
import i18n from 'i18next'
import jwtDecode from 'jwt-decode'
import LogRocket from 'logrocket'
import * as React from 'react'
import { useState } from 'react'
import { ApolloProvider } from 'react-apollo'
import { initReactI18next, useTranslation } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import client from './client'
import useNetwork from './hooks/useNetwork'
import resources from './i18n'
import { initSentry } from './lib/sentry'
import cross from './pages/xmark-solid.png'
import RouterPages from './router'
import './spinner.css'
import './styles/app.scss'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('key') || 'fr',
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false,
    },
  })

const App = () => {
  const userId = localStorage.getItem('access_token')
    ? jwtDecode(localStorage.getItem('access_token')).id
    : 'Unknown'
  const network = useNetwork()
  if (window.location.href.indexOf('localhost') === -1) {
    LogRocket.init('1azvau/test')
    LogRocket.identify(userId, {
      access_token: localStorage.getItem('access_token')
        ? localStorage.getItem('access_token')
        : 'No token',
    })
    const tracker = new Tracker({
      projectKey: 'Hu1f2h2wYcJ4tINnxeMx',
    })
    tracker.start()
    tracker.setUserID(userId)
    tracker.setMetadata('access_token', localStorage.getItem('access_token'))
  }
  const { t } = useTranslation('home')
  const [rehydrated, setRehydrated] = useState(false)
  const [longLoading, setLongLoading] = useState(false)

  window.onbeforeunload = () => {
    if (network) {
      indexedDB.deleteDatabase('apollo')
    }
    return 'ALERT'
  }

  const HandleClick = () => {
    setRehydrated(true)
  }

  setTimeout(() => {
    setLongLoading(true)
  }, 5000)

  React.useEffect(() => {
    client.hydrate().then(() => {
      setRehydrated(true)
      console.log('Hydrate')
    })
  }, [])
  if (!rehydrated) {
    return (
      <div className="global">
        <div className="ring" style={{ fontWeight: 'bolder' }} />
        {t('loading')}
        {longLoading ? (
          <img onClick={HandleClick} className="cross" src={cross} alt="close" />
        ) : (
          <></>
        )}
      </div>
    )
  }

  initSentry()

  return (
    <ApolloProvider client={client}>
      <div className="app">
        <BrowserRouter>
          <RouterPages />
        </BrowserRouter>
      </div>
      <ToastContainer />
    </ApolloProvider>
  )
}

export default App
