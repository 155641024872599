import { useLiveQuery } from 'dexie-react-hooks'
import React, { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { Button, Footer, Header, Input, InputSimple, OrderTable } from '../../components'
import { CONSTRUCTION_SITE_BY_CODE, TRUCK_BY_LICENSEPLATE } from '../../gql'
import { db } from '../../lib/db'
import './OrderSummaryStyle.scss'

const OrderSummary = () => {
  const { t } = useTranslation('orderSummary')
  const history = useHistory()
  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())
  const [destinationSite, setDestinationSite] = useState('')
  const [customer, setCustomer] = useState('')
  const [externalReference, setExternalReference] = useState('')
  const [forwarder, setForwarder] = useState('')
  const [constructionNb, setConstructionNb] = useState('')
  const [constructionId, setConstructionId] = useState(null)
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [plate, setPlate] = useState('')
  const [plateId, setPlateId] = useState(null)

  useEffect(() => {
    if (lastOrder) {
      setDestinationSite(lastOrder.destinationSite)
      setCustomer(lastOrder.customer)
      setExternalReference(lastOrder.externalReference)
      setForwarder(lastOrder.forwarder)
      setConstructionNb(lastOrder.constructionNb)
      setConstructionId(lastOrder.constructionId)
      setAddress(lastOrder.address)
      setCity(lastOrder.city)
      setPlate(lastOrder.plate)
      setPlateId(lastOrder.plate)
    }
  }, [lastOrder?.customer])

  const [error, setError] = useState(false)
  const [validating, setValidating] = useState(false)

  const [fetchIdTruck] = useLazyQuery(TRUCK_BY_LICENSEPLATE, {
    onCompleted: (response) => {
      if (response.trucks) {
        setPlateId(response?.trucks?.nodes[0]?.id)
      }
    },
  })

  const [fetchIdConstructionSite] = useLazyQuery(CONSTRUCTION_SITE_BY_CODE, {
    onCompleted: (response) => {
      if (response.constructionSiteByCode) {
        setConstructionId(response?.constructionSiteByCode?.id)
      }
    },
  })

  useEffect(() => {
    if (navigator.onLine) {
      if (constructionNb !== '') {
        fetchIdConstructionSite({
          variables: {
            code: constructionNb,
          },
        })
      }

      fetchIdTruck({
        variables: {
          plicensePlate: plate,
        },
      })
    }
  }, [constructionNb, plate])

  const validate = () => {
    const deliveredOutProduct = lastOrder.outMaterials
      ?.filter((product) => product.delivered)
      ?.map((o) => o.delivered)
    const deliveredInProduct = lastOrder.inMaterials
      ?.filter((product) => product.delivered)
      ?.map((o) => o.delivered)
    let sum1 = 0
    let sum2 = 0
    if (deliveredOutProduct.length) {
      sum1 = deliveredOutProduct.reduce((a, c) => a + c)
    }
    if (deliveredInProduct.length) {
      sum2 = deliveredInProduct.reduce((a, c) => a + c)
    }

    if (sum1 || sum2) {
      setValidating(true)
      db.offlineOrders.update(lastOrder.id, {
        ...lastOrder.infos,
        customer,
        externalReference,
        forwarder,
        plate,
        plateId,
        constructionNb,
        constructionId,
        address,
        city,
        destinationSite,
      })
      history.push('/sign')
    } else {
      setError(true)
    }
  }

  const deliverNum = (id, quantity, outProduct) => {
    if (outProduct) {
      lastOrder.outMaterials.forEach((m) => {
        const material = m
        if (material.id === id || material.matId === id) {
          material.delivered = parseFloat(quantity)
        }
      })
      db.offlineOrders.update(lastOrder.id, {
        outMaterials: [...lastOrder.outMaterials],
      })
    } else {
      lastOrder.inMaterials.forEach((m) => {
        const material = m
        if (material.id === id || material.matId === id) {
          material.delivered = parseFloat(quantity)
        }
      })
      db.offlineOrders.update(lastOrder.id, {
        inMaterials: [...lastOrder.inMaterials],
      })
    }
  }

  return (
    <>
      <Header title={t('orderSummary')} />
      <div className="summary">
        <div className="order-product-table">
          <OrderTable
            inProducts={lastOrder?.inMaterials ? lastOrder?.inMaterials : []}
            outProducts={lastOrder?.outMaterials ? lastOrder?.outMaterials : []}
            updateValue={(a, b, c) => deliverNum(a, b, c)}
          />
          <div className="add-link">
            <Link to="/catalog">{`+ ${t('add')}`}</Link>
          </div>
          {!!lastOrder?.inMaterials.length && (
            <div className="site">
              <p>{t('site')}</p>
              <div>
                <Input
                  type="text"
                  value={destinationSite}
                  placeholder={t('sitePlaceholder')}
                  onChange={(e) => setDestinationSite(e)}
                  required={true}
                />
              </div>
            </div>
          )}
          <p className="blue modify">{t('toModify')}</p>
          {error && <p className="error">{t('error')}</p>}
        </div>
        <div className="infos-half">
          <div className="order-infos">
            <div className="infos-line">
              <p>{t('customer')}</p>
              {lastOrder?.customerId && lastOrder?.customer ? (
                <p className="fake-input">{customer}</p>
              ) : (
                <InputSimple
                  type="text"
                  value={customer}
                  onChange={(e) => setCustomer(e)}
                  autoComplete={true}
                />
              )}
            </div>
            <div className="infos-line">
              <p>{t('externalReference')}</p>
              {lastOrder?.customerId && lastOrder?.externalReference ? (
                <p className="fake-input">{externalReference}</p>
              ) : (
                <InputSimple
                  type="text"
                  value={externalReference}
                  onChange={(e) => setExternalReference(e.toUpperCase())}
                />
              )}
            </div>
            <div className="infos-line">
              <p>{t('forwarder')}</p>
              {lastOrder?.customerId && lastOrder?.forwarder ? (
                <p className="fake-input">{forwarder}</p>
              ) : (
                <InputSimple
                  type="text"
                  value={forwarder}
                  onChange={(e) => setForwarder(e.toUpperCase())}
                />
              )}
            </div>
            <div className="infos-line">
              <p>{t('plate')}</p>
              {lastOrder?.customerId && lastOrder?.plate ? (
                <p className="fake-input">{plate}</p>
              ) : (
                <InputSimple
                  type="text"
                  value={plate}
                  onChange={(e) => setPlate(e.toUpperCase())}
                />
              )}
            </div>
            <div className="infos-line">
              <p>{t('constructionAddress')}</p>
              {lastOrder?.customerId && lastOrder?.address ? (
                <p className="fake-input">{address}</p>
              ) : (
                <InputSimple
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.toUpperCase())}
                />
              )}
            </div>
            <div className="infos-line">
              <p>{t('city')}</p>
              {lastOrder?.customerId && lastOrder?.city ? (
                <p className="fake-input">{city}</p>
              ) : (
                <InputSimple type="text" value={city} onChange={(e) => setCity(e.toUpperCase())} />
              )}
            </div>
            <div className="infos-line">
              <p>{t('constructionNb')}</p>
              {lastOrder?.customerId && lastOrder?.constructionNb ? (
                <p className="fake-input">{constructionNb}</p>
              ) : (
                <InputSimple
                  type="text"
                  value={constructionNb}
                  onChange={(e) => setConstructionNb(e.toUpperCase())}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer>
        <Button
          text={t('back')}
          type="outline"
          appearance="secondary"
          width="32%"
          onClick={() => history.push('/catalog')}
        />
        <Button
          text={t('quit')}
          type="outline"
          appearance="secondary"
          width="32%"
          onClick={() => history.push('/orders')}
        />
        <Button text={t('sign')} width="32%" loading={validating} onClick={() => validate()} />
      </Footer>
    </>
  )
}

export default OrderSummary
