import PropTypes from 'prop-types'
import React from 'react'
import './TableTabStyle.scss'

const TableTab = ({ tabs, selectedTab, onSelect }) => (
  <div className="table-tabs">
    {tabs &&
      tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => onSelect(tab)}
          className={`tab ${selectedTab === tab ? 'selected' : ''}`}
          type="button"
          style={{ width: `calc(100%/${tabs.length} - 15px)` }}
        >
          {tab}
        </button>
      ))}
  </div>
)

TableTab.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default TableTab
