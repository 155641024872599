import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import IconFlag from '../icons/IconFlag/IconFlag'
import { Label, OrderItemLayout, ProductDropDownHistory } from '../index'
import './HistoryItemStyle.scss'

const HistoryItem = ({ synced, status, customer, products, plateNumber, onDuplicate, report }) => {
  const { t } = useTranslation('history')
  return (
    <OrderItemLayout status={synced === true ? 'synced' : 'ok'} customer={customer}>
      <ProductDropDownHistory products={products} />
      <p className="weight-600">{plateNumber}</p>
      <div>
        {status === 'VALIDER' && (
          <Label icon="images/tovalidate.svg">
            <p>{t('toValidate')}</p>
          </Label>
        )}
        {status === 'REDRESSER' && (
          <Label danger icon="images/redflag.svg">
            <p>{t('toCorrect')}</p>
          </Label>
        )}
        {status === 'REDRESS_' && (
          <Label success icon="images/green-check.svg">
            <p>{t('corrected')}</p>
          </Label>
        )}
      </div>
      <div className="btn-group">
        <button className="no-style-btn" type="button" onClick={() => onDuplicate()}>
          <img src="images/duplicate.svg" alt="Dupliquer" />
        </button>

        <button className="no-style-btn" type="button" onClick={() => report()}>
          <IconFlag active={status === 'REDRESSER'} />
        </button>
      </div>
    </OrderItemLayout>
  )
}

HistoryItem.propTypes = {
  synced: PropTypes.bool,
  status: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  plateNumber: PropTypes.string.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  report: PropTypes.func.isRequired,
}

HistoryItem.defaultProps = {
  synced: true,
}

export default HistoryItem
