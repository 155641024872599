import { useLiveQuery } from 'dexie-react-hooks'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Clock from 'react-live-clock'
import { useHistory } from 'react-router-dom'
import useNetwork from '../../hooks/useNetwork'
import { db } from '../../lib/db'
import IconNetwork from '../icons/IconNetwork/IconNetwork'
import { Button, Modal } from '../index'
import './HeaderStyle.scss'

const Header = ({ title, onClick }) => {
  const history = useHistory()
  const network = useNetwork()
  const { t } = useTranslation('header')
  const [modal, setModal] = useState(false)
  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())

  const backToHome = () => {
    db.offlineOrders.delete(lastOrder.id)
    history.push('/endPending')
  }

  const onClickLogo = () => {
    if (lastOrder.completed === 'completed' && history.location.pathname === '/final') {
      onClick()
      history.push('/endPending')
    } else {
      setModal(true)
    }
  }

  return (
    <div className="header">
      {modal && (
        <Modal
          title={t('quitModal')}
          description={t('descModal')}
          cancelTxt={t('continue')}
          okTxt={t('quit')}
          onCancel={() => setModal(false)}
          onOk={backToHome}
        />
      )}
      <div className="header-fixed">
        <button
          type="button"
          onClick={() =>
            history.location.pathname === '/history' ? history.push('/endPending') : onClickLogo()
          }
          disabled={history.location.pathname === '/endPending'}
        >
          <img className="bdn-logo" src="/images/bdn-logo.png" alt={t('bdn')} />
        </button>
        {title ? (
          <h1>{title}</h1>
        ) : (
          <div className="historydiv">
            <Button
              icon="/images/clock.svg"
              text={t('history')}
              onClick={() => history.push('/history')}
            />
          </div>
        )}
        <IconNetwork active={network} />
        <div className="time">
          <Clock ticking={true} />
          <p>{moment().format('DD/MM/YY')}</p>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
}

Header.defaultProps = {
  title: '',
  onClick: null,
}

export default Header
