import { useLiveQuery } from 'dexie-react-hooks'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useClickAway } from 'use-click-away'
import { db } from '../../lib/db'
import { AutoCompleteList } from '../index'
import './InputModalStyle.scss'

const InputModal = ({ value, placeholder, type, setValue, closeModal, autoCompleteList }) => {
  const [inputValue, setInputValue] = useState(value)
  const [autoComplete, setAutoComplete] = useState(false)

  const ref = React.useRef(null)
  useClickAway(ref, () => {
    setValue(inputValue)
    closeModal()
  })

  const list = useLiveQuery(async () => {
    if (inputValue) {
      const clts = await db.clients
        .where('displayName')
        .startsWithIgnoreCase(inputValue)
        .offset(0)
        .limit(5)
        .toArray()
      return clts
    }
    return []
  }, [inputValue])

  return (
    <div className="input-modal" style={{ paddingTop: autoComplete ? '10px' : '80px' }}>
      <div ref={ref} className="input-inside">
        <input
          autoFocus={true}
          type={type}
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => {
            setInputValue(e.target.value)
            if (e.target.value && autoCompleteList) {
              setAutoComplete(true)
            } else {
              setAutoComplete(false)
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setValue(e.target.value)
              closeModal()
            }
          }}
        />

        {autoComplete && autoCompleteList && list?.length > 0 && (
          <AutoCompleteList
            list={list}
            select={(content) => {
              setValue(content)
              setAutoComplete(false)
              closeModal()
            }}
          />
        )}
      </div>
    </div>
  )
}

InputModal.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  autoCompleteList: PropTypes.bool,
}

InputModal.defaultProps = {
  value: '',
  autoCompleteList: false,
  placeholder: '',
}

export default InputModal
