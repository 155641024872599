/* eslint-disable radix */
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { useLiveQuery } from 'dexie-react-hooks'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuid } from 'uuid'
import {
  Button,
  Footer,
  Header,
  InputSimple,
  Modal,
  PrintDocument,
  ReviewItem,
} from '../../components'
import config from '../../config'
import {
  CONSTRUCTION_SITE_BY_CODE,
  CREATE_DELIVERIES,
  CREATE_DELIVERIY_ITEMS,
  GET_DELIVERIES,
  GET_SITE_BY_ID,
  GET_USER,
  TRUCK_BY_LICENSEPLATE,
} from '../../gql'
import { db } from '../../lib/db'
import { captureMessageWithAttachment } from '../../lib/sentry'
import './EndReviewStyle.scss'

const EndReview = () => {
  const { t } = useTranslation('endReview')
  const history = useHistory()
  const { search } = window.location
  const query = new URLSearchParams(search)
  const [seed] = useState(uuid())
  const [customer, setCustomer] = useState('')
  const [customerId, setCustomerId] = useState(null)
  const [externalReference, setExternalReference] = useState('')
  const [forwarder, setForwarder] = useState('')
  const [constructionId, setConstructionId] = useState(null)
  const [constructionNb, setConstructionNb] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [plate, setPlate] = useState('')
  const [plateId, setPlateId] = useState(null)
  const [destinationSite, setDestinationSite] = useState('')
  const [modal, setModal] = useState(false)
  const [print, setPrint] = useState(false)
  const [created, setCreated] = useState(false)
  const [deliveryCode, setDeliveryCode] = useState(null)
  const [signature, setSignature] = useState('')
  const replacements = useMemo(
    () => ({
      à: 'a',
      á: 'a',
      â: 'a',
      ä: 'a',
      è: 'e',
      é: 'e',
      ê: 'e',
      ë: 'e',
      ì: 'i',
      í: 'i',
      î: 'i',
      ï: 'i',
      ò: 'o',
      ó: 'o',
      ô: 'o',
      ö: 'o',
      ù: 'u',
      ú: 'u',
      û: 'u',
      ü: 'u',
      ç: 'c',
      À: 'A',
      Á: 'A',
      Â: 'A',
      Ä: 'A',
      È: 'E',
      É: 'E',
      Ê: 'E',
      Ë: 'E',
      Ì: 'I',
      Í: 'I',
      Î: 'I',
      Ï: 'I',
      Ò: 'O',
      Ó: 'O',
      Ô: 'O',
      Ö: 'O',
      Ù: 'U',
      Ú: 'U',
      Û: 'U',
      Ü: 'U',
      Ç: 'C',
    }),
    []
  )

  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())

  const getForwarderId = useLiveQuery(async () => {
    const fw = await db.forwarders.where('displayName').equals(forwarder).toArray()
    return fw[0]?.id
  }, [forwarder])

  const [fetchSiteCode, { data: siteCode }] = useLazyQuery(GET_SITE_BY_ID)
  const [fetchHistoryData, { data: historyData }] = useLazyQuery(GET_DELIVERIES)
  const { data: userData } = useQuery(GET_USER, {
    variables: {
      id: jwtDecode(localStorage.getItem('access_token')).id,
    },
    onCompleted: (data) => {
      fetchSiteCode({ variables: { id: data?.user?.idSite } })
      fetchHistoryData({
        variables: {
          site: data?.user?.idSite,
          startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
          endDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        },
      })
    },
  })

  const [createDeliveryMaterial] = useMutation(CREATE_DELIVERIY_ITEMS, {
    onCompleted: (d) => {
      lastOrder?.outMaterials.forEach((m) => {
        const material = m
        if (
          material.materialByIdMaterial?.id ===
            d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.idMaterial ||
          material.matId === d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.idMaterial
        ) {
          material.graphqlId = d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.id
        }
      })

      lastOrder?.inMaterials.forEach((m) => {
        const material = m
        if (
          material.materialByIdMaterial?.id ===
            d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.idMaterial ||
          material.matId === d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.idMaterial
        ) {
          material.graphqlId = d.createDraftOutboundDeliveryItem.draftOutboundDeliveryItem.id
        }
      })
      db.offlineOrders.update(lastOrder?.id, lastOrder)
    },
    onError: (err) => {
      const request = CREATE_DELIVERIY_ITEMS
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          idDraftOutboundDelivery: seed,
          materials: lastOrder?.outMaterials,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })
  const [fetchIdTruck] = useLazyQuery(TRUCK_BY_LICENSEPLATE, {
    onCompleted: (response) => {
      if (response.trucks) {
        setPlateId(response?.trucks?.nodes[0]?.id)
      }
    },
  })
  const [fetchIdConstructionSite] = useLazyQuery(CONSTRUCTION_SITE_BY_CODE, {
    onCompleted: (response) => {
      if (response.constructionSiteByCode) {
        setConstructionId(response?.constructionSiteByCode?.id)
        setCity(response?.constructionSiteByCode?.city)
        setAddress(response?.constructionSiteByCode.address)
      }
    },
  })

  useEffect(() => {
    if (lastOrder) {
      setCustomer(lastOrder?.customer)
      setCustomerId(lastOrder?.customerId)
      setExternalReference(lastOrder?.externalReference)
      setForwarder(lastOrder?.forwarder)
      setConstructionId(lastOrder?.constructionId)
      setConstructionNb(lastOrder?.constructionNb)
      setAddress(lastOrder?.address)
      setCity(lastOrder?.city)
      setPlate(lastOrder?.plate)
      setPlateId(lastOrder?.plate)
      setDestinationSite(lastOrder?.destinationSite)
      setCreated(lastOrder?.completed === 'completed')
      if (!deliveryCode) {
        setDeliveryCode(lastOrder?.deliveryCode)
      }
      if (!signature) {
        setSignature(lastOrder?.signature)
      }
      if (lastOrder?.errorSign) {
        Sentry.captureMessage(`[Signature Error]: ${lastOrder?.errorSign}`)
      }
    }
  }, [lastOrder])

  useEffect(() => {
    if (navigator.onLine) {
      if (constructionNb !== '') {
        fetchIdConstructionSite({ variables: { code: constructionNb } })
      }
      fetchIdTruck({ variables: { plicensePlate: plate } })
    }
  }, [constructionNb, plate])

  useEffect(() => {
    if (print) {
      window.print()
    }
  }, [print])

  const checkIfAllIsCreated = () => {
    let count = 0
    const products = []
    lastOrder?.outMaterials.forEach((materials) => {
      if (materials.graphqlId) {
        count += 1
      }
      products.push({
        quantity: materials.delivered,
        materialByIdMaterial: {
          id: materials?.id || materials?.materialByIdMaterial?.id,
          description: materials?.description || materials?.materialByIdMaterial?.description,
          productCategoryByIdProductCategory: {
            family: 'MATERIAL',
          },
        },
      })
    })

    lastOrder?.inMaterials.forEach((materials) => {
      if (materials.graphqlId) {
        count += 1
      }
      products.push({
        quantity: materials.delivered,
        materialByIdMaterial: {
          id: materials?.id || materials?.materialByIdMaterial?.id,
          description: materials?.description || materials?.materialByIdMaterial?.description,
          productCategoryByIdProductCategory: {
            family: 'RUBBLE',
          },
        },
      })
    })

    if (lastOrder?.outMaterials?.length + lastOrder?.inMaterials?.length === count) {
      db.offlineOrders.delete(lastOrder.id)
    } else {
      // add new delivery in offline db
      db.deliveries.add({
        id: seed,
        idSite: userData?.user?.idSite,
        idSaleOrder: lastOrder?.orderId || null,
        idConstructionSite: constructionId,
        constructionSiteAddress: address || '',
        constructionSiteCity: city || '',
        constructionSiteCode: constructionNb || '',
        truckLicencePlate: plate || '',
        saleOrderClientName: customer || '',
        idClient: customerId || null,
        createdAt: moment().format(),
        idInternalTransfert:
          query.get('process') === 'evacuate' ? config.idInternalTransfert : null,
        forwardingAgent: forwarder || '',
        idFreightForwarder: getForwarderId || null,
        externalReference: externalReference || '',
        destinationSite,
        offline: true,
        status: 'VALIDER',
        products,
      })

      // active toast last order is offline
      toast('Le bon a été mis en offline', {
        toastId: 'blOffline', // Prevent duplicate toasts
      })
    }

    history.push('/endPending')
  }

  const createDeliveryItems = () => {
    if (lastOrder?.outMaterials.length > 0) {
      for (let index = 0; index < lastOrder?.outMaterials.length; index++) {
        const element = lastOrder?.outMaterials[index]
        const id = uuid()
        if (element.delivered) {
          createDeliveryMaterial({
            variables: {
              id,
              idDraftOutboundDelivery: seed,
              idMaterial: element.matId || element.materialByIdMaterial.id,
              quantity: element.delivered,
            },
          })
        }
      }
    }
    if (lastOrder?.inMaterials.length > 0) {
      for (let index = 0; index < lastOrder?.inMaterials.length; index++) {
        const element = lastOrder?.inMaterials[index]
        const id = uuid()
        if (element.delivered) {
          createDeliveryMaterial({
            variables: {
              id,
              idDraftOutboundDelivery: seed,
              idMaterial: element.matId || element.materialByIdMaterial.id,
              quantity: element.delivered,
            },
          })
        }
      }
    }
  }

  const [newDelivery] = useMutation(CREATE_DELIVERIES, {
    onCompleted: (data) => {
      db.offlineOrders.update(lastOrder?.id, {
        graphqlDeliveryId: data?.createDraftOutboundDelivery?.draftOutboundDelivery?.id,
      })
      createDeliveryItems()
    },
    onError: (err) => {
      const request = CREATE_DELIVERIES
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: seed,
          idSite: userData?.user?.idSite,
          idSaleOrder: lastOrder?.orderId || null,
          idConstructionSite: constructionId,
          constructionSiteAddress: address || '',
          constructionSiteCity: city || '',
          constructionSiteCode: constructionNb || '',
          truckLicencePlate: plate || '',
          saleOrderClientName: customer || '',
          idClient: customerId || null,
          value: moment().format(),
          idInternalTransfert:
            query.get('process') === 'evacuate' ? config.idInternalTransfert : null,
          code: deliveryCode,
          forwardingAgent: forwarder || '',
          idFreightForwarder: getForwarderId || null,
          externalReference: externalReference || '',
          destinationSite,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  window.onafterprint = () => {
    setCreated(true)
  }

  const validate = async () => {
    setPrint(true)

    if (!created) {
      let codeGenerated = lastOrder?.deliveryCode

      if (!codeGenerated) {
        if (siteCode) {
          const firstPart = siteCode?.site?.name?.substr(0, 2).toUpperCase()
          const secondPart = dayjs().format('YYYYMMDDHHmmss')
          const thirdPart = String(
            historyData?.draftOutboundDeliveries?.nodes.filter(
              (e) => dayjs(e.createdAt).format('MMM Do YY') === dayjs().format('MMM Do YY')
            ).length + 1
          ).padStart(2, '0')
          if (thirdPart === 'NaN') {
            codeGenerated = `${firstPart + secondPart}00`
          } else {
            codeGenerated = firstPart + secondPart + thirdPart
          }
        } else {
          codeGenerated = lastOrder?.destinationSite
        }

        codeGenerated = codeGenerated?.replace(
          /[àáâäèéêëìíîïòóôöùúûüçÀÁÂÄÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÇ]/g,
          (matched) => replacements[matched]
        )

        setDeliveryCode(codeGenerated)
      }
      db.offlineOrders.update(lastOrder?.id, {
        ...lastOrder,
        customer,
        forwarder,
        idFreightForwarder: getForwarderId || null,
        plate,
        plateId,
        constructionNb,
        constructionId,
        address,
        city,
        destinationSite,
        deliveryCode: codeGenerated,
      })

      if (navigator.onLine) {
        newDelivery({
          variables: {
            id: seed,
            idSite: userData?.user?.idSite,
            idSaleOrder: lastOrder?.orderId || null,
            idConstructionSite: constructionId,
            idTruck: plateId,
            constructionSiteAddress: address || '',
            constructionSiteCity: city || '',
            constructionSiteCode: constructionNb || '',
            truckLicencePlate: plate || '',
            saleOrderClientName: customer || '',
            idClient: customerId || null,
            value: dayjs().format(),
            idInternalTransfert:
              query.get('process') === 'evacuate' ? config.idInternalTransfert : null,
            code: codeGenerated,
            forwardingAgent: forwarder || '',
            idFreightForwarder: getForwarderId || null,
            externalReference: externalReference || '',
            destinationSite,
          },
        })
      }
    }
  }

  const reviewTable = () => (
    <div className="reviews-list">
      {lastOrder?.outMaterials
        ?.filter((product) => product.delivered)
        .map((product) => (
          <ReviewItem key={product.id} product={product} toSend={true} />
        ))}
      {lastOrder?.inMaterials
        ?.filter((product) => product.delivered)
        .map((product) => (
          <ReviewItem key={product.id} product={product} />
        ))}
    </div>
  )

  return (
    <div>
      {print ? (
        <PrintDocument
          lastOrderId={lastOrder?.id}
          id={seed}
          customer={customer}
          code={deliveryCode}
          products={lastOrder?.inMaterials.concat(lastOrder?.outMaterials)}
          address={address}
          city={city}
          plate={plate}
          t={t}
          signature={signature}
          closeDoc={() => {
            setPrint(false)
            db.offlineOrders.update(lastOrder?.id, {
              completed: 'completed',
            })
            checkIfAllIsCreated()
          }}
          forwarder={forwarder}
        />
      ) : (
        <div>
          <Header
            title={query.get('process') === 'evacuate' ? t('evacuateSummary') : t('endReview')}
            onClick={() => checkIfAllIsCreated()}
          />
          {modal && (
            <Modal
              title={t('quitModal')}
              description={t('descModal')}
              cancelTxt={t('continue')}
              okTxt={t('quit')}
              onCancel={() => setModal(false)}
              onOk={() => history.push('/endPending')}
            />
          )}
          <div className="end-review">
            <div className="infos-half">
              <div className="order-infos">
                <div className="infos-line">
                  <p>{t('customer')}</p>
                  {lastOrder?.customerId && lastOrder?.customer ? (
                    <p className="fake-input">{customer}</p>
                  ) : (
                    <InputSimple
                      type="text"
                      value={customer}
                      onChange={(e) => setCustomer(e)}
                      autoComplete={true}
                    />
                  )}
                </div>
                {query.get('process') !== 'evacuate' && (
                  <div className="infos-line">
                    <p>{t('externalReference')}</p>
                    {lastOrder?.externalReferenceId && lastOrder?.externalReference ? (
                      <p className="fake-input">{externalReference}</p>
                    ) : (
                      <InputSimple
                        type="text"
                        value={externalReference}
                        onChange={(e) => setExternalReference(e)}
                      />
                    )}
                  </div>
                )}
                <div className="infos-line">
                  <p>{t('forwarder')}</p>
                  {lastOrder?.customerId && lastOrder?.forwarder ? (
                    <p className="fake-input">{forwarder}</p>
                  ) : (
                    <InputSimple type="text" value={forwarder} onChange={(e) => setForwarder(e)} />
                  )}
                </div>
                <div className="infos-line">
                  <p>{t('plate')}</p>
                  {lastOrder?.customerId && lastOrder?.plate ? (
                    <p className="fake-input">{plate}</p>
                  ) : (
                    <InputSimple type="text" value={plate} onChange={(e) => setPlate(e)} />
                  )}
                </div>
                {query.get('process') !== 'evacuate' ? (
                  <div>
                    <div className="infos-line">
                      <p>{t('constructionAddress')}</p>
                      {lastOrder?.customerId && lastOrder?.address ? (
                        <p className="fake-input">{address}</p>
                      ) : (
                        <InputSimple type="text" value={address} onChange={(e) => setAddress(e)} />
                      )}
                    </div>
                    <div className="infos-line">
                      <p>{t('city')}</p>
                      {lastOrder?.customerId && lastOrder?.city ? (
                        <p className="fake-input">{city}</p>
                      ) : (
                        <InputSimple type="text" value={city} onChange={(e) => setCity(e)} />
                      )}
                    </div>
                    <div className="infos-line">
                      <p>{t('constructionNb')}</p>
                      {lastOrder?.customerId && lastOrder?.constructionNb ? (
                        <p className="fake-input">{constructionNb}</p>
                      ) : (
                        <InputSimple
                          type="text"
                          value={constructionNb}
                          onChange={(e) => setConstructionNb(e)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="infos-line">
                      <p>{t('sitePlaceholder')} :</p>
                      <InputSimple
                        type="text"
                        value={destinationSite}
                        onChange={(e) => setDestinationSite(e)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="order-product-table">
              <div className="review-table">
                <p className="product-title">{t('products')}</p>
                {reviewTable()}
              </div>
              {query.get('view') !== 'true' && (
                <>
                  <p className="sign-title">{t('signature')}</p>
                  <div className="signature">
                    <img src={signature} alt="" />
                  </div>
                </>
              )}
            </div>
          </div>
          <Footer>
            {query.get('view') === 'true' ? (
              <Button
                text={t('back')}
                type="outline"
                appearance="secondary"
                width="32%"
                onClick={() => history.push('/sign')}
              />
            ) : (
              <>
                {created ? (
                  <Button
                    text={t('leave')}
                    type="outline"
                    appearance="secondary"
                    width="48.5%"
                    onClick={() => checkIfAllIsCreated()}
                  />
                ) : (
                  <Button
                    text={t('quit')}
                    type="outline"
                    appearance="secondary"
                    width="48.5%"
                    onClick={() => setModal(true)}
                  />
                )}
                <Button text={t('print')} width="48.5%" onClick={() => validate()} />
              </>
            )}
          </Footer>
        </div>
      )}
    </div>
  )
}

export default EndReview
