import { useLiveQuery } from 'dexie-react-hooks'
import React, { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Footer, Header, Input } from '../../components'
import { CONSTRUCTION_SITE_BY_CODE, TRUCK_BY_LICENSEPLATE } from '../../gql'
import { db } from '../../lib/db'
import './NewOrderStyle.scss'

const NewOrder = () => {
  const { t } = useTranslation('newOrder')
  const history = useHistory()
  const lastOrder = useLiveQuery(async () => db.offlineOrders.orderBy('id').last())
  const [customer, setCustomer] = useState('')
  const [customerId, setCustomerId] = useState(null)
  const [externalReference, setExternalReference] = useState('')
  const [forwarder, setForwarder] = useState('')
  const [constructionNb, setConstructionNb] = useState('')
  const [constructionId, setConstructionId] = useState(null)
  const [validating, setValidating] = useState(false)
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [plate, setPlate] = useState('')
  const [plateId, setPlateId] = useState(null)

  useEffect(() => {
    if (lastOrder) {
      setCustomer(lastOrder.customer)
      setCustomerId(lastOrder.customerId)
      setExternalReference(lastOrder.externalReference)
      setForwarder(lastOrder.forwarder)
      setConstructionNb(lastOrder.constructionNb)
      setConstructionId(lastOrder.constructionNb)
      setAddress(lastOrder.address)
      setCity(lastOrder.city)
      setPlate(lastOrder.plate)
      setPlateId(lastOrder.plate)
    }
  }, [lastOrder])

  const [error, setError] = useState(false)
  const [fetchIdTruck] = useLazyQuery(TRUCK_BY_LICENSEPLATE, {
    onCompleted: (response) => {
      if (response.trucks) {
        setPlateId(response?.trucks?.nodes[0]?.id)
      }
    },
  })

  const [fetchIdConstructionSite] = useLazyQuery(CONSTRUCTION_SITE_BY_CODE, {
    onCompleted: (response) => {
      if (response.constructionSiteByCode) {
        setConstructionId(response?.constructionSiteByCode?.id)
      }
    },
  })

  useEffect(() => {
    if (navigator.onLine) {
      if (constructionNb !== '') {
        fetchIdConstructionSite({
          variables: {
            code: constructionNb,
          },
        })
      }

      fetchIdTruck({
        variables: {
          plicensePlate: plate,
        },
      })
    }
  }, [constructionNb, plate])

  const validate = async () => {
    if (!customer || !plate) {
      setError(true)
    } else {
      setValidating(true)
      db.offlineOrders.update(lastOrder.id, {
        customerId,
        customer,
        forwarder,
        plate,
        plateId,
        constructionNb,
        address,
        city,
        externalReference,
        constructionId,
      })
      history.push('/catalog')
    }
  }

  return (
    <div>
      <Header title={t('newOrder')} />
      <div className="new-order">
        <div className="parts">
          <div className="input-container">
            <p>{`${t('customer')} :*`}</p>
            <Input
              width="100%"
              placeholder={t('customerName')}
              value={customer}
              onChange={(value) => {
                setCustomer(value?.displayName)
                setCustomerId(value?.id)
              }}
              required
              error={error}
              autoComplete={true}
              type="text"
            />
          </div>
          <div className="input-container">
            <p>{`${t('externalReference')} :`}</p>
            <Input
              width="100%"
              placeholder={t('externalReference')}
              value={externalReference}
              onChange={(e) => setExternalReference(e.toUpperCase())}
              type="text"
            />
          </div>
          <div className="input-container">
            <p>{`${t('forwarder')} :`}</p>
            <Input
              width="100%"
              placeholder={t('forwarderName')}
              value={forwarder}
              onChange={(e) => setForwarder(e.toUpperCase())}
              type="text"
            />
          </div>
          <div className="input-container">
            <p>{`${t('constructionNb')} :`}</p>
            <Input
              width="100%"
              placeholder={t('constructionNb2')}
              value={constructionNb}
              onChange={(e) => setConstructionNb(e.toUpperCase())}
              type="text"
            />
          </div>
        </div>
        <div className="parts">
          <div className="input-container">
            <p>{`${t('constructionAddress')} :`}</p>
            <Input
              width="100%"
              placeholder={t('constructionAddress')}
              value={address}
              onChange={(e) => setAddress(e.toUpperCase())}
              type="text"
            />
          </div>
          <div className="input-container">
            <p>{`${t('constructionCity')} :`}</p>
            <Input
              width="100%"
              placeholder={t('constructionCity')}
              value={city}
              onChange={(e) => setCity(e.toUpperCase())}
              type="text"
            />
          </div>
          <div className="input-container">
            <p>{`${t('plate')} :*`}</p>
            <Input
              width="100%"
              placeholder={t('plate')}
              value={plate}
              onChange={(e) => setPlate(e.toUpperCase())}
              type="text"
              required
              error={error}
            />
          </div>
        </div>
      </div>
      <Footer>
        <Button
          text={t('quit')}
          type="outline"
          appearance="secondary"
          onClick={() => history.push('/orders')}
          width="210px"
        />
        <Button text={t('next')} width="25%" loading={validating} onClick={() => validate()} />
      </Footer>
    </div>
  )
}

export default NewOrder
